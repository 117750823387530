export const maturityOptions = [
	{ label: "5 bulan", value: "5" },
	{ label: "6 bulan", value: "6" },
	{ label: "7 bulan", value: "7" },
	{ label: "8 bulan", value: "8" },
	{ label: "9 bulan", value: "9" },
	{ label: "10 bulan", value: "10" },
	{ label: "11 bulan", value: "11" },
	{ label: "12 bulan", value: "12" },
];

export const financingOptions = [
	{ label: "60%", value: "60" },
	{ label: "70%", value: "70" },
	{ label: "80%", value: "80" },
	{ label: "90%", value: "90" },
];

export const dropDownOptions = [
	{ label: "Rejected", value: "rejected" },
	{ label: "Approved", value: "approved" },
];

export const statusOptions = [
	{ label: "Pending", value: "pending" },
	{ label: "Reject", value: "reject" },
	{ label: "Active", value: "active" },
];

export const allStatusOptions = [
	{ label: "Pending", value: "pending" },
	{ label: "Awaiting", value: "awaiting" },
	{ label: "Rejected", value: "rejected" },
	{ label: "Approved", value: "approved" },
];

export const statusBatchOptions = [
	{ label: "Expired", value: "non-active" },
	{ label: "Active", value: "active" },
];

export const monthOptions = [
	{ label: "January", value: "01" },
	{ label: "February", value: "02" },
	{ label: "March", value: "03" },
	{ label: "April", value: "04" },
	{ label: "May", value: "05" },
	{ label: "June", value: "06" },
	{ label: "July", value: "07" },
	{ label: "August", value: "08" },
	{ label: "September", value: "09" },
	{ label: "October", value: "10" },
	{ label: "November", value: "11" },
	{ label: "December", value: "12" }
];