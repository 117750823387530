import React from "react";
import PropTypes from "prop-types";
import { Img, Heading } from "..";

export const Header = React.forwardRef(
	(
		className = "",
		...props
	) => {
		const username = localStorage.getItem('username');
		return (
			<header { ...props } className={ `${ className } flex flex-row justify-center items-center w-full p-0.5 bg-indigo-900 !fixed top-0 left-0 z-50` }>
				<div className="flex flex-row justify-between items-center w-full mx-auto px-8 h-[6vh]">
					<Heading size="2xl" as="h5" className="!text-2xl !text-yellow-800 !font-leaguespartan !font-bold ">
						Fintech Asset Pro
					</Heading>
					<div className="flex flex-row justify-center w-[14%]">
						<div className="flex flex-row justify-start items-center w-full gap-3">
							<div className="flex flex-col items-end justify-start w-[60%] gap-px">
								<Heading size="s" as="p" className="!text-white-A700">
									{ username }
								</Heading>
								<Heading size="xs" as="p" className="mr-[3px] !text-orange-A700">
									Administrator
								</Heading>
							</div>
							<Img src="images/img_person.svg" alt="photo_one" className="w-[32px] object-cover" />
						</div>
					</div>
				</div>
			</header>
		);
	},
);

Header.propTypes = {
	className: PropTypes.string,
};
