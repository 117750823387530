import React, { useState, useEffect, useMemo } from 'react';
import { Img, SelectBox, Text, Button, Input } from "..";
import { CheckBox } from 'components/CheckBox';
import { maturityOptions, financingOptions } from 'constant/options';

const TableRow = ({ checkbox, refNo, financing, maturity, marhun, outstanding, disabled }) => {
	return (
		<div className={ `flex items-center justify-center w-full hover:bg-gray-50_02 py-5 ${ disabled && 'bg-gray-200' }` }>
			<CheckBox className="w-[5%]" value={ checkbox } />
			<div className='w-[19%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ refNo }
				</Text>
			</div>
			<div className='w-[19%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ maturity.split('T')[0] }
				</Text>
			</div>
			<div className='w-[19%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ marhun }
				</Text>
			</div>
			<div className='w-[19%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ financing }
				</Text>
			</div>
			<div className='w-[19%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ outstanding }
				</Text>
			</div>
		</div>
	);
};

const TableUpdateRedeem = ({ data, batchOption, blockedFinancingNo, onFinancingChange, onMaturityChange, onReplaceBatch, setTotalReplacement, onBlockedFinancingNo, totalReplacement }) => {
	const [selectedMaturity, setSelectedMaturity] = useState('');
	const [selectedFinancing, setSelectedFinancing] = useState('');
	const [maxValue, setMaxValue] = useState('');
	const [checkedItems, setCheckedItems] = useState(Array.isArray(data) ? Array(data.length).fill(false) : []);
	const [selectAll, setSelectAll] = useState(false);
	const [sortedData, setSortedData] = useState([]);
	const [sortBy, setSortBy] = useState('financing_no');
	const [sortDirection, setSortDirection] = useState('asc');
	const [selectedBatchId, setSelectedBatchId] = useState('');
	const [submitError, setSubmitError] = useState('');

	const handleReplaceBatch = async () => {
		const selectedBatchOption = batchOption.find(option => option.batchId === selectedBatchId.value);
		const totalReplacementPrev = (totalReplacement && totalReplacement.find(item => item.batchId === selectedBatchId.value)) || { batchId: "", totalReplacementAmount: 0 };

		const total = totalReplacementPrev.totalReplacementAmount + countTotalFinancingAmount();
		if (total < selectedBatchOption.totalFinancing) {
			setSubmitError("Total replacement amount must be greater than the total outstanding redemption.");
			return;
		} else {
			const checkedItemsData = sortedData.filter((item, index) => checkedItems[index]);
			const totalReplacementAmount = countTotalFinancingAmount();
			const selectedItems = checkedItemsData.map(item => ({
				data: item.reporting_data,
				batchId: selectedBatchId.value,
			}));
			setCheckedItems(Array(filteredData.length).fill(false));
			onReplaceBatch(selectedItems);
			onFinancingChange('');
			onMaturityChange('');
			setSelectedMaturity('');
			setSelectedFinancing('');
			setMaxValue('');
			setSelectAll('');
			setSortedData(filteredData);
			const blocked = checkedItemsData.map(item => (item.reporting_data));
			onBlockedFinancingNo(blocked);
			setTotalReplacement({ batchId: selectedBatchId.value, totalReplacementAmount: totalReplacementAmount });
		}

		setSubmitError('');
	};

	const filteredData = useMemo(() => {
		return data ? data.filter(item => item.reporting_data.reporting_status === "reported") : [];
	}, [data]);

	const handleSort = (column) => {
		if (sortBy === column) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(column);
			setSortDirection('desc');
		}
	};

	useEffect(() => {
		const sorted = [...filteredData].sort((a, b) => {
			const aValue = a.reporting_data[sortBy];
			const bValue = b.reporting_data[sortBy];
			if (aValue < bValue) {
				return sortDirection === 'asc' ? -1 : 1;
			}
			if (aValue > bValue) {
				return sortDirection === 'asc' ? 1 : -1;
			}
			return 0;
		});
		setSortedData(sorted);
	}, [filteredData, sortBy, sortDirection]);

	const countTotalMahunValue = () => {
		if (!sortedData || !checkedItems) return 0;
		const totalMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (checkedItems[index]) {
				return accumulator + currentItem.reporting_data.marhun_amount;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalMahunValue.toFixed(2));
	};

	const countTotalFinancingAmount = () => {
		if (!sortedData || !checkedItems) return 0;
		const totalCheckedMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (checkedItems[index]) {
				return accumulator + currentItem.reporting_data.financing_amount;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalCheckedMahunValue.toFixed(2));
	};

	const handleFilterChange = () => {
		onFinancingChange(selectedFinancing);
		onMaturityChange(selectedMaturity);

		if (maxValue) {
			let currentSum = 0;
			const filteredByMaxValue = [];
			const remainingItems = [];

			for (const item of filteredData) {
				const financingAmount = item.reporting_data.financing_amount;
				const isDisabled = blockedFinancingNo && blockedFinancingNo.some(selectedItem => selectedItem.financing_no === item.reporting_data.financing_no);

				if (!isDisabled) {
					if (currentSum + financingAmount <= maxValue) {
						filteredByMaxValue.push(item);
						currentSum += financingAmount;
					} else {
						remainingItems.push(item);
					}
				}
			}
			for (const item of remainingItems) {
				const financingAmount = item.reporting_data.financing_amount;
				if (currentSum + financingAmount <= maxValue) {
					filteredByMaxValue.push(item);
					currentSum += financingAmount;
					if (currentSum >= maxValue) break;
				}
			}
			setSortedData(filteredByMaxValue);
		} else {
			setSortedData(filteredData);
		}
	};

	const options = batchOption.map(option => ({
		label: option.batchId,
		value: option.batchId
	}));

	const disabledItems = useMemo(() => {
		if (!blockedFinancingNo) return [];
		return sortedData.map(item => blockedFinancingNo.some(selectedItem => selectedItem.financing_no === item.reporting_data.financing_no));
	}, [blockedFinancingNo, sortedData]);

	const handleItemClick = (index) => {
		if (!disabledItems[index]) {
			const newCheckedItems = [...checkedItems];
			newCheckedItems[index] = !newCheckedItems[index];
			setCheckedItems(newCheckedItems);
		}
	};

	const handleSelectAll = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		const newCheckedItems = newSelectAll
			? filteredData.map((item, index) => (!disabledItems[index] ? true : false))
			: Array(filteredData.length).fill(false);
		setCheckedItems(newCheckedItems);
	};

	return (
		<div className="flex flex-col items-center justify-start w-full gap-5">

			<div className="flex flex-row  w-full items-end gap-5">
				<div className='flex flex-col gap-2 w-[30%]'>
					<Text className='!text-blue_gray-100_02 !font-bold'>Select Batch to Replace</Text>
					<SelectBox
						indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" className="absolute" /> }
						name="batch"
						placeholder=""
						options={ options }
						onChange={ (value) => setSelectedBatchId(value) }
						className="w-full gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
					/>
				</div>
				<div className='flex flex-col gap-2 w-[20%]'>
					<Text className='!text-blue_gray-100_02 !font-bold'>Remaining Maturity (Min)</Text>
					<SelectBox
						indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" className="absolute" /> }
						name="month"
						placeholder=""
						options={ maturityOptions }
						value={ selectedMaturity }
						onChange={ (value) => setSelectedMaturity(value) }
						className="w-full gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
					/>
				</div>
				<div className='flex flex-col gap-2 w-[20%]'>
					<Text className='!text-blue_gray-100_02 !font-bold'>Financing Limit ( % Max )</Text>
					<SelectBox
						indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" className="absolute" /> }
						name="status"
						placeholder=""
						options={ financingOptions }
						value={ selectedFinancing }
						onChange={ (value) => setSelectedFinancing(value) }
						className="w-full gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
					/>
				</div>
				<div className='flex flex-col gap-2 w-[20%]'>
					<Text className='!text-blue_gray-100_02 !font-bold'>Max Financing Value</Text>
					<Input
						type="number"
						name="MaxValue"
						value={ maxValue }
						onChange={ (value) => setMaxValue(value) }
						className="!h-[42px] w-full gap-px !font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
					/>
				</div>
				<Button onClick={ handleFilterChange } color="yellow_800" size="sm" className="font-bold min-w-[126px]">
					Filter
				</Button>
			</div>
			<div className="flex items-center justify-between w-full gap-10">
				<div className="flex flex-row justify-start gap-10 items-end w-full">
					<div className='flex flex-col gap-3'>
						<Text className='!text-blue-800 !font-bold'>Total Selected Marhun Value</Text>
						<Text className='!text-yellow-100 !font-bold'>{ countTotalMahunValue() }</Text>
					</div>
					<div className='flex flex-col gap-3'>
						<Text className='!text-blue-800 !font-bold'>Total selected Financing Amount</Text>
						<Text className='!text-yellow-100 !font-bold'>{ countTotalFinancingAmount() }</Text>
					</div>
				</div>
				<Button color="yellow_800" size="md" disable={ !selectedBatchId || !checkedItems || !checkedItems.some(item => item) } onClick={ handleReplaceBatch } className="!text-sm font-bold whitespace-nowrap">
					Replace Batch
				</Button>
			</div>
			{ submitError && <Text as="p" className="text-red-500 w-full justify-start">{ submitError }</Text> }
			<div className='w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-10'>
				<div className="flex flex-col  w-full ">
					<div className="flex items-center justify-center w-full pb-5">
						<CheckBox className="w-[5%]" onChange={ handleSelectAll } value={ selectAll } />
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] ">
								Financing Ref No
							</Text>
							<Img onClick={ () => handleSort('financing_no') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'financing_no' && '!rotate-180' }` } />
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Maturity Date
							</Text>
							<Img onClick={ () => handleSort('maturity_date') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'maturity_date' && '!rotate-180' }` } />
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Marhun Value (RM)
							</Text>
							<Img onClick={ () => handleSort('marhun_amount') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'marhun_amount' && '!rotate-180' }` } />
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Financing Amount (RM)
							</Text>
							<Img onClick={ () => handleSort('disbursement_date') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'financing_amount' && '!rotate-180' }` } />
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Outstanding Balance (RM)
							</Text>
							<Img onClick={ () => handleSort('financing_amount') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'outstanding_balance' && '!rotate-180' }` } />
						</div>
					</div>
					<div className='h-px bg-orange-100 w-full'></div>
					<div className="flex flex-col items-center justify-start w-full pb-10 h-[70vh] overflow-y-auto">
						{ sortedData.map((item, index) => (
							<div key={ index } className='w-full' onClick={ () => handleItemClick(index) }>
								<TableRow
									refNo={ item.reporting_data.financing_no }
									disbursement={ item.reporting_data.disbursement_date }
									maturity={ item.reporting_data.maturity_date }
									marhun={ item.reporting_data.marhun_amount }
									financing={ item.reporting_data.financing_amount }
									outstanding={ item.reporting_data.outstanding_balance }
									status={ item.reporting_data.reporting_status }
									checkbox={ checkedItems[index] }
									disabled={ blockedFinancingNo && blockedFinancingNo.some(selectedItem => selectedItem.financing_no === item.reporting_data.financing_no) }
								/>
								<div className='h-px bg-orange-100 w-full'></div>
							</div>
						)) }
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableUpdateRedeem;