import React, { useEffect, useState } from "react";
import { Header, DefaultSideBar, Text, Button } from "components";
import TableDetailRedeem from "components/TableEditRedeem";
import TableUpdateRedeem from "components/TableUpdateRedeem";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { changeStatusRedeem, detailRedeem, exportRedeem, submitRedeem } from "services/redeem";
import { reportingsData } from "services/report";
import TableResumeRedeem from "components/TableResumeRedeem";
import * as loader from "../../components/Loading/loading";

export default function DetailRedeem() {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('id');

	const [data, setData] = useState(null);
	const [dataReport, setDataReport] = useState(null);
	const [selectedMaturity, setSelectedMaturity] = useState('');
	const [selectedFinancing, setSelectedFinancing] = useState('');
	const [selectedBatch, setSelectedBatch] = useState(null);
	const [blockedFinancing, setBlockedFinancing] = useState(null);
	const [totalReplacement, setTotalReplacement] = useState([]);
	const [submitError, setSubmitError] = useState('');

	const fetchData = async () => {
		try {
			const redeemData = await detailRedeem(id);
			setData(redeemData.data);
			fetchDataAssets(redeemData.data.redeem_data.registration_id);
		} catch (error) {
			console.log("Error fetching batch data:", error);
		}
	};

	const fetchDataAssets = async (regId) => {
		if (regId) {
			try {
				const reportsData = await reportingsData("", "", "", selectedMaturity, "", "", selectedFinancing, regId);
				setDataReport(reportsData.data);
			} catch (error) {
				console.error("Error fetching reporting data:", error);
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		fetchDataAssets();
	}, [selectedMaturity, selectedFinancing]);

	const handleMaturityChange = (criteria) => {
		setSelectedMaturity(criteria.value);
	};

	const handleFinancingChange = (criteria) => {
		setSelectedFinancing(criteria.value);
	};

	const handleTotalReplacementChange = (criteria) => {
		const existingIndex = totalReplacement.findIndex(item => item.batchId === criteria.batchId);

		if (existingIndex !== -1) {
			const updatedTotalReplacement = [...totalReplacement];
			updatedTotalReplacement[existingIndex].totalReplacementAmount += criteria.totalReplacementAmount;
			setTotalReplacement(updatedTotalReplacement);
		} else {
			setTotalReplacement([...totalReplacement, criteria]);
		}
	};

	const handleBlockedFinancingNo = (criteria) => {
		const newArray = blockedFinancing ? [...blockedFinancing, ...criteria] : [...criteria];
		setBlockedFinancing(newArray);
	};

	const handleSelectedBatch = (criteria) => {
		const newSelectedBatch = selectedBatch ? [...selectedBatch] : [];

		criteria.forEach(newItem => {
			const existingIndex = newSelectedBatch.findIndex(item => item.batchId === newItem.batchId);
			if (existingIndex !== -1) {
				newSelectedBatch[existingIndex].data.push(newItem.data);
			} else {
				const groupBatch = data.group_batch;
				const batch = groupBatch.find(item => item.batch_id === newItem.batchId);
				const prevData = batch?.reportings;
				const prevFinancingAmount = batch?.total_financing_amount;
				newSelectedBatch.push({
					batchId: newItem.batchId,
					data: [newItem.data],
					prevData,
					prevFinancingAmount,
				});
			}
		});

		setSelectedBatch(newSelectedBatch);
	};

	const handleReplaceBatch = () => {
		const allBatchesSelected = data.group_batch.every(batch => {
			return selectedBatch.some(selected => selected.batchId === batch.batch_id);
		});
		if (allBatchesSelected) {

			setSubmitError("");
			handleSubmitRedeem();
		} else {
			setSubmitError("Please select all batches before replacing.");
			return;
		}
		setSubmitError("");
	};

	const handleSubmitRedeem = async () => {
		loader.showLoader();
		const dataRegId = data.redeem_data.registration_id;
		const batches = selectedBatch.map(({ batchId, data, prevData }) => {
			const batchingData = [];
			prevData.forEach(({ financing_no }) => {
				batchingData.push({ financing_no, status: "expired" });
			});
			data.forEach(({ financing_no }) => {
				batchingData.push({ financing_no, status: "batched" });
			});
			return {
				batch_id: batchId,
				registration_id: dataRegId,
				batching_data: batchingData
			};
		});
		const redeemHistories = selectedBatch.map(({ batchId, data, prevData, prevFinancingAmount }) => {
			return {
				batch_id: batchId,
				updated_data: data,
				previous_data: prevData,
				previous_outstanding: prevFinancingAmount,
				updated_outstanding: totalReplacement.find(replacement => replacement.batchId === batchId)?.totalReplacementAmount,
				approved_time: new Date().toISOString()
			};
		});
		try {
			const response = await submitRedeem(Object.values(batches));
			if (response) {
				try {
					const statusResponse = await changeStatusRedeem(id);
					if (statusResponse) {
						const exportResponse = await exportRedeem(id, redeemHistories);
						if (exportResponse) {
							navigate(`/resume-redeem?id=${ id }`);
						} else {
							setSubmitError("Failed to export redeem histories.");
						}
					} else {
						setSubmitError("Failed to change status after redeem.");
					}
				} catch (error) {
					setSubmitError("Failed to change status after redeem.");
				}
			}
		} catch (error) {
			setSubmitError(error);
		} finally {
			loader.hideLoader();
		}
	};

	return (
		<div className=" w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
			<Header />
			<DefaultSideBar />
			{ data && dataReport ? (
				<div className="flex flex-col gap-10">
					<div className="grid grid-cols-4 gap-10 px-10">
						<div className="flex flex-col items-start justify-start w-full mt-5 gap-7 h-[95vh] overflow-y-auto">
							{ data.group_batch.map((item, index) => (
								<TableDetailRedeem key={ index } data={ item } totalReplacement={ totalReplacement.find(replacement => replacement.batchId === item.batch_id)?.totalReplacementAmount || 0 } />
							)) }
						</div>
						<div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] col-span-3">
							<TableUpdateRedeem
								data={ dataReport.reportings }
								batchOption={ data.group_batch.map(item => ({ batchId: item.batch_id, totalFinancing: item.total_financing_amount })) }
								onFinancingChange={ handleFinancingChange }
								onMaturityChange={ handleMaturityChange }
								onReplaceBatch={ handleSelectedBatch }
								dataSelected={ selectedBatch }
								dataCount={ selectedBatch }
								setTotalReplacement={ handleTotalReplacementChange }
								totalReplacement={ totalReplacement }
								onBlockedFinancingNo={ handleBlockedFinancingNo }
								blockedFinancingNo={ blockedFinancing }
							/>
						</div>
					</div>
					<div className="flex flex-col items-start justify-start w-full px-10 mt-10">
						<TableResumeRedeem data={ selectedBatch } totalReplacement={ totalReplacement } />
					</div>
					{/* <div className="flex flex-col items-start justify-start w-full px-10 mt-10">
						<TableResumeBatch data={ dataCount } dataTotal={ data.group_batch } />
					</div> */}
					{ submitError && <Text as="p" className="text-red-500 w-full justify-start px-10">{ submitError }</Text> }

					<Button onClick={ handleReplaceBatch } disable={ data.redeem_data.status === "granted" } color="yellow_800" size="xl" className="!text-xl font-bold my-10 mx-80">
						Submit Replace Batch
					</Button>
				</div>
			) : (
				<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
			) }
		</div>
	);

}
