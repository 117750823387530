import React, { useState } from "react";
import { Text, Button, Input, Heading, Img } from "../../components";
import { login } from '../../services/auth';
import { useNavigate } from 'react-router';
import * as loader from "../../components/Loading/loading";

export default function LoginPage() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginError, setLoginError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const isFormValid = email !== '' && password !== '';

	const handleEmailChange = (event) => {
		setEmail(event);
	};

	const handlePasswordChange = (event) => {
		setPassword(event);
	};

	const handleLogin = async () => {
		loader.showLoader();
		try {
			const isLoggedIn = await login(email, password);
			if (isLoggedIn) {
				navigate('/');
			}
		} catch (error) {
			setLoginError("Failed to login. Please check your credentials.");
		} finally {
			loader.hideLoader();
		}
	};

	return (
		<div className="bg-white flex justify-center items-center h-screen w-screen bg-gray-100">
			<div className="flex flex-col items-center justify-center gap-[17px] px-20 w-[30%]">
				<div className="flex flex-col items-center justify-center w-full mt-[19px] gap-[13px]">
					<Heading size="9xl" as="h2" className="mt-0.5 !text-blue_gray-900_03 text-center">
						Log in to your account
					</Heading>
				</div>
				<div className="flex flex-col items-start justify-start w-full gap-6">
					<div className="flex flex-col items-center justify-start w-full gap-5">
						<div className="flex flex-col items-start justify-start w-full gap-[7px]">
							<Text as="p" className="!text-blue_gray-700">
								Email
							</Text>
							<Input
								type="email"
								name="email"
								value={ email }
								onChange={ handleEmailChange }
								placeholder="Enter your email"
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
						</div>
						<div className="flex flex-col items-start justify-start w-full gap-[7px]">
							<Text as="p" className="!text-blue_gray-700">
								Password
							</Text>
							<Input
								type={ showPassword ? 'text' : 'password' }
								name="password"
								value={ password }
								placeholder="••••••••"
								onChange={ handlePasswordChange }
								suffix={
									<Img
										src={ showPassword ? "images/img_eyes_close.svg" : "images/img_eyes.svg" }
										onClick={ () => setShowPassword(!showPassword) }
										className="cursor-pointer w-4 h-4"
									/>
								}
								className="w-full border-blue_gray-100_02 border border-solid"
							/>
						</div>
					</div>
					{ loginError && <Text as="p" className="text-red-500">{ loginError }</Text> }
					<Button color="yellow_800" onClick={ handleLogin } disabled={ !isFormValid } size="lg" className="w-full ml-px font-bold !shadow-sm !text-lg">
						Sign in
					</Button>
				</div>
			</div>
		</div>
	);
}
