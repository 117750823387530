import React, { useState } from 'react';
import { Img, SelectBox, Input, Heading, Text } from "..";
import { CloseSVG } from "../../assets/images";
import { CheckBox } from 'components/CheckBox';
import { useNavigate } from 'react-router';
import { actionKyc } from 'services/originator';
import ModalRejected from 'components/ModalRejected';
import { allStatusOptions } from 'constant/options';
import * as loader from "../../components/Loading/loading";

const TableRow = ({ checkbox, company, id, status }) => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const handleToDetail = () => {
		status && navigate(`/originator-detail?id=${ id }`);
	};
	const handleActionKyc = async (status, reason) => {
		loader.showLoader();
		try {
			const response = await actionKyc(status, reason, id);
			if (response) {
				window.location.reload();
			}
		} catch (error) {
			console.log("error action kyc");
		} finally {
			loader.hideLoader();
		}
	};
	const handleApproveKyc = () => {
		handleActionKyc('approved', "");
	};

	const handleRejectKyc = (reason) => {
		handleActionKyc('rejected', reason);
		setTimeout(() => {
			setIsOpen(false);
		}, 2000);
	};
	return (
		<div className="flex items-center justify-center w-full hover:bg-gray-50_02">
			<CheckBox className="w-[7%]" value={ checkbox } />
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] w-[28%]">
				{ id }
			</Text>
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] w-[40%] col-span-2">
				{ company }
			</Text>
			<Text as="p" className={ `text-center !font-semibold tracking-[-0.14px] w-[15%] ${ status === "approved" ? '!text-green ' : status === "rejected" ? '!text-red-A700' : status === 'awaiting' ? '!text-yellow-800' : '!text-black-900' }` }>
				{ status.charAt(0).toUpperCase() + status.slice(1) }
			</Text>
			<div className='flex justify-center items-center w-[10%]'>
				<Img
					onClick={ handleToDetail }
					src="images/img_carbon_view.svg"
					alt="vector_one"
					className="h-[24px] w-[24px] ml-2.5 my-[17px]"
				/>
				<Img
					onClick={ handleApproveKyc }
					src="images/img_ic_baseline_check.svg"
					alt="materialsymbol"
					className="h-[24px] w-[24px] ml-2.5 my-[17px]"
				/>
				<Img
					onClick={ () => setIsOpen(true) }
					src="images/img_ic_outline_close.svg"
					alt="materialsymbol"
					className="h-[24px] w-[24px] ml-2.5"
				/>
			</div>
			<ModalRejected isOpen={ isOpen } onYesButtonClick={ handleRejectKyc } onNoButtonClick={ () => setIsOpen(false) } />
		</div>
	);
};

const Table = ({ data }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(50);
	const [searchTerm, setSearchTerm] = useState('');
	const [checkedItems, setCheckedItems] = useState(Array.isArray(data) ? Array(data.length).fill(false) : []);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(null);

	const handleSearch = (e) => {
		setSearchTerm(e);
		setCurrentPage(1);
	};

	const handleSelectStatus = (selectedStatus) => {
		setSelectedStatus(selectedStatus);
	};

	const handleItemClick = (index) => {
		const newCheckedItems = [...checkedItems];
		newCheckedItems[index] = !newCheckedItems[index];
		setCheckedItems(newCheckedItems);
	};
	const handleSelectAll = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		const newCheckedItems = newSelectAll ? Array(data.length).fill(true) : Array(data.length).fill(false);
		setCheckedItems(newCheckedItems);
	};
	// Pagination
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const filteredData = data ? data.filter((item) => item.company.toLowerCase().includes(searchTerm.toLowerCase()) &&
		(!selectedStatus || item.kyc_status === selectedStatus.value)
	) : [];
	const sortedData = filteredData.slice().sort((a, b) => {
		return new Date(b.created_at) - new Date(a.created_at);
	});

	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="flex flex-col items-center justify-start w-full gap-5">
			<div className="flex flex-row justify-between items-center w-full">
				<div className="flex flex-row justify-start items-center gap-[19px]">
					<Heading size="xl" as="h1" className="!text-gray-700 text-center !font-bold">
						Apply Filters :
					</Heading>
					<SelectBox
						indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" /> }
						name="status"
						placeholder="Status"
						options={ allStatusOptions }
						onChange={ handleSelectStatus }
						className="w-[160px] gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid rounded-[5px]"
					/>
				</div>

				<div className="flex flex-row justify-start items-center gap-[15px]">
					<Input
						name="search"
						placeholder="Search Name"
						onChange={ handleSearch }
						suffix={
							searchTerm?.length > 0 ? (
								<CloseSVG
									onClick={ () => setSearchTerm("") }
									height={ 27 }
									width={ 31 }
									fillColor="#7e7e7eff"
								/>
							) : <Img src="images/img_search.svg" alt="search" className="cursor-pointer" />
						}
						className="w-[267px] gap-[35px] text-gray-700 border-gray-300 border-2 border-dashed"
					/>
					{/* <Button color="yellow_800" size="sm" className="font-bold min-w-[126px]">
						Filter
					</Button> */}
				</div>
			</div>

			<div className='w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-10'>
				{/* Table */ }
				<div className="flex flex-col  w-full ">
					<div className="flex items-center justify-center w-full pb-5">
						<CheckBox className="w-[7%]" onChange={ handleSelectAll } value={ selectAll } />
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[28%]">
							Member ID
						</Text>
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[40%] col-span-2">
							Company Name
						</Text>
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] text-center w-[15%]">
							Status
						</Text>
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] text-center w-[10%]">
							Action
						</Text>

					</div>
					<div className='h-px bg-orange-100 w-full'></div>
					<div className="flex flex-col items-center justify-start w-full">
						{ currentItems.map((item, index) => (
							<div key={ index } className='w-full' onClick={ () => handleItemClick(index) }>
								<TableRow
									id={ item.id }
									company={ item.company }
									checkbox={ checkedItems[index] }
									status={ item.kyc_status }
								/>
								<div className='h-px bg-orange-100 w-full'></div>
							</div>
						)) }
					</div>
				</div>

				{/* Pagination */ }
				<div className="flex flex-row justify-end items-center w-full">
					<div className="flex gap-3">
						<div
							onClick={ currentPage > 1 ? () => paginate(currentPage - 1) : undefined }
							className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
						>
							{ `<` }
						</div>
						{ Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
							<div key={ i } onClick={ () => paginate(i + 1) } className={ `rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs ${ currentPage === i + 1 ? 'bg-yellow-800 text-white-A700' : 'bg-gray-100_01 text-black-900' } ` }>{ i + 1 }</div>
						)) }
						<div
							onClick={ currentPage < Math.ceil(filteredData.length / itemsPerPage) ? () => paginate(currentPage + 1) : undefined }
							className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
						>
							{ '>' }
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Table;