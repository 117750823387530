import { token } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function originator() {
	try {
		const response = await fetch(`${ API_URL }/externalusers/registrationdata?limit=0&page=0&filter=`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function detailOriginator(id) {
	try {
		const response = await fetch(`${ API_URL }/externalusers/registrationdata/${ id }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function actionKyc(status, reason, id) {
	try {
		const response = await fetch(`${ API_URL }/externalusers/registrationdata/${ id }`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
			body: JSON.stringify({
				kyc_status: status,
				kyc_rejection_reason: status === 'rejected' ? reason : '',
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}