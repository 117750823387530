import React, { useEffect, useState } from "react";
import { Header, DefaultSideBar } from "components";
import { reportingsData } from "services/report";
import TableCreateBatch from "components/TableCreateBatch";
import { useLocation } from 'react-router-dom';

export default function CreateBatch() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('id');

	const [data, setData] = useState(null);
	const [selectedMaturity, setSelectedMaturity] = useState('');
	const [selectedFinancing, setSelectedFinancing] = useState('');
	const [maxValue, setMaxValue] = useState('');

	const fetchData = async () => {
		try {
			const reportsData = await reportingsData(id, "", maxValue, selectedMaturity, "", "", selectedFinancing, "");
			setData(reportsData.data);
		} catch (error) {
			console.error("Error fetching reporting data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [selectedMaturity, selectedFinancing, maxValue]);

	const handleMaturityChange = (criteria) => {
		setSelectedMaturity(criteria.value);
	};

	const handleFinancingChange = (criteria) => {
		setSelectedFinancing(criteria.value);
	};

	const handleMaxValueChange = (criteria) => {
		setMaxValue(criteria);
	};

	return (
		<div className=" w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
			<Header />
			<DefaultSideBar />
			{ data ? (
				<div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] px-10">
					<TableCreateBatch data={ data.reportings } onFinancingChange={ handleFinancingChange } onMaturityChange={ handleMaturityChange } onMaxValueChange={ handleMaxValueChange } />
				</div>
			) : (
				<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
			) }
		</div>
	);
}
