import { token } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function redeem() {
	try {
		const response = await fetch(`${ API_URL }/redeems?limit=0&page=0&filter=`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function detailRedeem(id) {
	try {
		const response = await fetch(`${ API_URL }/redeems/${ id }?detailed=true`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function submitRedeem(batches) {
	try {
		const response = await fetch(`${ API_URL }/batchings`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
			body: JSON.stringify({
				batches: batches,
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function changeStatusRedeem(id) {
	try {
		const response = await fetch(`${ API_URL }/redeems/${ id }`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
			body: JSON.stringify({
				status: "granted",
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function deleteRedeem(id) {
	try {
		const response = await fetch(`${ API_URL }/redeems/${ id }`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function exportRedeem(id, redeemHistories) {
	try {
		const response = await fetch(`${ API_URL }/redeem_histories`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
			body: JSON.stringify({
				redeem_id: id,
				redeem_histories: redeemHistories
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}
export async function getExportRedeem(id) {
	try {
		const response = await fetch(`${ API_URL }/redeem_histories/${ id }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}
