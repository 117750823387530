import React, { useState, useEffect, useMemo } from 'react';
import { Img, SelectBox, Input, Heading, Text, Button } from "..";
import { CloseSVG } from "../../assets/images";
import { CheckBox } from 'components/CheckBox';
import ModalCreateBatch from 'components/ModalCreateBatch';
import { maturityOptions, financingOptions } from 'constant/options';
import { createBatch } from 'services/batch';
import { useNavigate } from 'react-router';
import * as loader from "../../components/Loading/loading";

const TableRow = ({ checkbox, refNo, disbursement, maturity, marhun, financing, principal, status }) => {
	return (
		<div className={ `flex items-center justify-center w-full py-5 ${ status === "expired" ? 'bg-gray-200' : 'hover:bg-gray-50_02' }` }>
			<CheckBox className="w-[3%]" value={ checkbox } />
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ refNo }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ disbursement.split('T')[0] }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ maturity.split('T')[0] }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ marhun }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ financing }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ principal }
				</Text>
			</div>
			<div className='w-[7%] flex justify-center'>
				<Text as="p" className={ `${ status === 'reported' ? '!text-green' : '!text-red-A700' } !font-semibold tracking-[-0.14px]` }>
					{ status.charAt(0).toUpperCase() + status.slice(1) }
				</Text>
			</div>
		</div>
	);
};


const TableCreateBatch = ({ data, onFinancingChange, onMaturityChange, onMaxValueChange }) => {
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedMaturity, setSelectedMaturity] = useState('');
	const [selectedFinancing, setSelectedFinancing] = useState('');
	const [maxValue, setMaxValue] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [submitError, setSubmitError] = useState('');
	const [sortedData, setSortedData] = useState([]);
	const [sortBy, setSortBy] = useState('financing_no');
	const [sortDirection, setSortDirection] = useState('asc');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);
	const [selectAllPage, setSelectAllPage] = useState([]);

	const handleCreateBatch = async () => {
		loader.showLoader();
		try {
			const checkedItemsData = sortedData.filter(item => selectedItems[item.reporting_data.financing_no]);
			const financingNumbers = checkedItemsData.map(item => ({
				financing_no: item.reporting_data.financing_no,
				status: "batched"
			}));
			const successCreate = await createBatch(data[0].registration_id, financingNumbers);
			if (successCreate) {
				setIsOpen(false);
				navigate('/batch');
			}
		} catch (error) {
			setSubmitError(error);
		} finally {
			loader.hideLoader();
		}
	};

	const handleSearch = (e) => {
		setSearchTerm(e);
	};

	const handleSelectItem = (financing_no) => {
		setSelectedItems(prevSelectedItems => {
			const newSelectedItems = { ...prevSelectedItems };
			if (newSelectedItems[financing_no]) {
				delete newSelectedItems[financing_no];
			} else {
				newSelectedItems[financing_no] = true;
			}
			return newSelectedItems;
		});
	};

	const handleSelectAll = (pageNumber) => {
		const newSelectAllPage = [...selectAllPage];
		const pageIndex = newSelectAllPage.indexOf(pageNumber);
		const newSelectAll = pageIndex === -1;

		if (newSelectAll) {
			newSelectAllPage.push(pageNumber);
		} else {
			newSelectAllPage.splice(pageIndex, 1);
		}

		setSelectAllPage(newSelectAllPage);

		setSelectedItems(prevSelectedItems => {
			const newSelectedItems = { ...prevSelectedItems };
			const selectAll = newSelectAll;

			currentItems.forEach(item => {
				if (selectAll) {
					newSelectedItems[item.reporting_data.financing_no] = true;
				} else {
					delete newSelectedItems[item.reporting_data.financing_no];
				}
			});

			return newSelectedItems;
		});
	};

	useEffect(() => {
		if (selectAllPage.includes(currentPage)) {
			setSelectAll(true);
		} else {
			setSelectAll(false);
		}
	}, [currentPage, selectAllPage]);

	const filteredData = useMemo(() => {
		return data ? data.filter(item => item.reporting_data.financing_no.toLowerCase().includes(searchTerm.toLowerCase())) : [];
	}, [data, searchTerm]);

	const handleSort = (column) => {
		if (sortBy === column) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(column);
			setSortDirection('desc');
		}
	};


	useEffect(() => {
		const sorted = [...filteredData].sort((a, b) => {
			const aValue = a.reporting_data[sortBy];
			const bValue = b.reporting_data[sortBy];
			if (aValue < bValue) {
				return sortDirection === 'asc' ? -1 : 1;
			}
			if (aValue > bValue) {
				return sortDirection === 'asc' ? 1 : -1;
			}
			return 0;
		});
		setSortedData(sorted);
	}, [filteredData, sortBy, sortDirection]);

	const countTotalMahunValue = () => {
		if (!sortedData || !selectedItems) return 0;
		const totalMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (selectedItems[currentItem.reporting_data.financing_no]) {
				return accumulator + currentItem.reporting_data.marhun_amount;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalMahunValue.toFixed(2));
	};
	const countTotalFinancingValue = () => {
		if (!sortedData || !selectedItems) return 0;
		const totalCheckedMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (selectedItems[currentItem.reporting_data.financing_no]) {
				return accumulator + currentItem.reporting_data.financing_amount;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalCheckedMahunValue.toFixed(2));
	};
	const countTotalOutstandingBalance = () => {
		if (!sortedData || !selectedItems) return 0;
		const totalCheckedMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (selectedItems[currentItem.reporting_data.financing_no]) {
				return accumulator + currentItem.reporting_data.outstanding_balance;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalCheckedMahunValue.toFixed(2));
	};
	const countTotalProfitAmt = () => {
		if (!sortedData || !selectedItems) return 0;
		const totalCheckedMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (selectedItems[currentItem.reporting_data.financing_no]) {
				return accumulator + currentItem.reporting_data.profit_rate_per_annum;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalCheckedMahunValue.toFixed(2));
	};

	const handleFilterChange = () => {
		onFinancingChange(selectedFinancing);
		onMaturityChange(selectedMaturity);
		onMaxValueChange(maxValue);
		setSelectedItems([]);
	};

	// Pagination
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const renderPageNumbers = () => {
		const totalPages = Math.ceil(filteredData.length / itemsPerPage);
		const maxPagesToShow = 10;
		let startPage = 1;
		let endPage = totalPages;

		if (totalPages > maxPagesToShow) {
			startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
			endPage = startPage + maxPagesToShow - 1;

			if (endPage > totalPages) {
				endPage = totalPages;
				startPage = endPage - maxPagesToShow + 1;
			}
		}

		const pageNumbers = [];
		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}

		return (
			<div className="flex flex-row justify-end items-center w-full">
				<div className="flex gap-3">
					<div
						onClick={ currentPage > 1 ? () => paginate(currentPage - 1) : undefined }
						className="cursor-pointer bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
					>
						{ `<` }
					</div>
					<div className="pagination flex justify-end gap-3">
						{ startPage > 1 && (
							<Button onClick={ () => paginate(1) } className={ `cursor-pointer rounded-md font-medium border-gray-200 border border-solid flex justify-center items-center text-xs ${ currentPage === 0 ? '!bg-yellow-800 !text-white-A700' : '!bg-gray-100_01 !text-black-900' } ` }>
								1
							</Button>
						) }
						{ startPage > 2 && (
							<span>...</span>
						) }
						{ pageNumbers.map(number => (
							<Button key={ number } onClick={ () => paginate(number) } active={ currentPage === number } className={ `cursor-pointer rounded-md font-medium border-gray-200 border border-solid flex justify-center items-center text-xs ${ currentPage === number ? '!bg-yellow-800 !text-white-A700' : '!bg-gray-100_01 !text-black-900' } ` }>
								{ number }
							</Button>
						)) }
						{ endPage < totalPages - 1 && (
							<span>...</span>
						) }
						{ endPage < totalPages && (
							<Button onClick={ () => paginate(totalPages) } className={ `rounded-md font-medium border-gray-200 border border-solid flex justify-center items-center text-xs ${ currentPage === totalPages ? '!bg-yellow-800 !text-white-A700' : '!bg-gray-100_01 !text-black-900' } ` }>
								{ totalPages }
							</Button>
						) }
					</div>
					<div
						onClick={ currentPage < Math.ceil(filteredData.length / itemsPerPage) ? () => paginate(currentPage + 1) : undefined }
						className="cursor-pointer bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
					>
						{ '>' }
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col items-center justify-start w-full gap-5">
			<div className="flex flex-col items-center w-full">
				<div className="flex flex-row justify-between w-full items-center">
					<Heading size="xl" as="h1" className="!text-gray-700 text-center !font-bold">
						Apply Filters :
					</Heading>
					{ selectedItems.length !== 0 ?
						<Button onClick={ () => { setIsOpen(true); setSubmitError(''); } } color="blue_900" size="md" className="!text-sm font-bold min-w-[126px]">
							Create Batch
						</Button> :
						<Input
							name="search"
							value={ searchTerm }
							placeholder="Search Name"
							onChange={ handleSearch }
							suffix={
								searchTerm?.length > 0 ? (
									<CloseSVG
										onClick={ () => setSearchTerm("") }
										height={ 27 }
										width={ 31 }
										fillColor="#7e7e7eff"
									/>
								) : <Img src="images/img_search.svg" alt="search" className="cursor-pointer" />
							}
							className="w-[267px] gap-[35px] text-gray-700 border-gray-300 border-2 border-dashed"
						/>
					}
				</div>
				<div className="flex flex-row justify-start items-end gap-10 w-full">
					<div className='flex flex-col gap-2'>
						<Text className='!text-blue_gray-100_02 !font-bold'>Remaining Maturity (Min)</Text>
						<SelectBox
							indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" className="absolute" /> }
							name="month"
							placeholder=""
							options={ maturityOptions }
							onChange={ (value) => setSelectedMaturity(value) }
							className="w-full gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
						/>
					</div>
					<div className='flex flex-col gap-2'>
						<Text className='!text-blue_gray-100_02 !font-bold'>Financing Limit ( % Max )</Text>
						<SelectBox
							indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" className="absolute" /> }
							name="status"
							placeholder=""
							options={ financingOptions }
							onChange={ (value) => setSelectedFinancing(value) }
							className="w-full gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
						/>
					</div>
					<div className='flex flex-col gap-2'>
						<Text className='!text-blue_gray-100_02 !font-bold'>Max Financing Value</Text>
						<Input
							type="number"
							name="MaxValue"
							onChange={ (value) => setMaxValue(value) }
							className="!h-[42px] w-full gap-px !font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
						/>
					</div>
					<Button onClick={ handleFilterChange } color="yellow_800" size="sm" className="font-bold min-w-[126px]">
						Filter
					</Button>
					<div className='flex flex-col gap-3'>
						<Text className='!text-blue-800 !font-bold'>Total Selected Marhun Value</Text>
						<Text className='!text-yellow-100 !font-bold'>{ countTotalMahunValue() }</Text>
					</div>
					<div className='flex flex-col gap-3'>
						<Text className='!text-blue-800 !font-bold'>Total Selected Financing Amount</Text>
						<Text className='!text-yellow-100 !font-bold'>{ countTotalFinancingValue() }</Text>
					</div>
				</div>
			</div>
			<div className='w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-10 mb-10'>
				<div className="flex flex-col  w-full ">
					<div className="flex items-center justify-center w-full pb-5">
						<CheckBox className="w-[3%]" onChange={ () => handleSelectAll(currentPage) } value={ selectAll } />
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] ">
								SAG No / Financing Ref No
							</Text>
							<Img onClick={ () => handleSort('financing_no') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'financing_no' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Disbursement Date
							</Text>
							<Img onClick={ () => handleSort('disbursement_date') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'disbursement_date' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Maturity Date
							</Text>
							<Img onClick={ () => handleSort('maturity_date') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'maturity_date' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Marhun Value (RM)
							</Text>
							<Img onClick={ () => handleSort('marhun_amount') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'marhun_amount' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Financing Amount (RM)
							</Text>
							<Img onClick={ () => handleSort('financing_amount') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'financing_amount' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Outstanding Balance (RM)
							</Text>
							<Img onClick={ () => handleSort('outstanding_balance') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'outstanding_balance' && '!rotate-180' }` } />
						</div>
						<div className='w-[7%] flex justify-center items-center'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Status
							</Text>
						</div>
					</div>
					<div className='h-px bg-orange-100 w-full'></div>
					<div className="flex flex-col items-center justify-start w-full pb-5">
						{ currentItems.map((item, index) => (
							<div key={ index } className='w-full' onClick={ () => handleSelectItem(item.reporting_data.financing_no) }>
								<TableRow
									refNo={ item.reporting_data.financing_no }
									disbursement={ item.reporting_data.disbursement_date }
									maturity={ item.reporting_data.maturity_date }
									marhun={ item.reporting_data.marhun_amount }
									financing={ item.reporting_data.financing_amount }
									principal={ item.reporting_data.outstanding_balance }
									checkbox={ selectedItems[item.reporting_data.financing_no] }
									status={ item.reporting_data.reporting_status }
								/>
								<div className='h-px bg-orange-100 w-full'></div>
							</div>
						)) }
					</div>
				</div>
				{ renderPageNumbers() }
			</div>
			<ModalCreateBatch isOpen={ isOpen } submitError={ submitError } totalLoan={ Object.keys(selectedItems).length } marhunValue={ countTotalMahunValue() } outstandingBalance={ countTotalOutstandingBalance() } financingAmt={ countTotalFinancingValue() } ProfitAmt={ countTotalProfitAmt() } onButtonClick={ () => setIsOpen(false) } onSubmitButtonClick={ handleCreateBatch } />
		</div>
	);
};

export default TableCreateBatch;