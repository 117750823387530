import { token } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function batch() {
	try {
		const response = await fetch(`${ API_URL }/batchings?limit=0&page=0&filter=&exclude_expired=true`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function detailBatch(id, minMaturity = "", maxMaturity = "", minFinancing = "", maxFinancing = "",) {
	try {
		const response = await fetch(`${ API_URL }/batchings/${ id }?exclude_expired=true&min_remaining_maturity=${ minMaturity }&max_remaining_maturity=${ maxMaturity }&min_financing_limit=${ minFinancing }&max_financing_limit=${ maxFinancing }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function createBatch(registrationId, batchingData) {
	try {
		const response = await fetch(`${ API_URL }/batchings`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
			body: JSON.stringify({
				registration_id: registrationId,
				batching_data: batchingData
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function deleteBatch(id) {
	try {
		const response = await fetch(`${ API_URL }/batchings/${ id }`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function updateBatch(registrationId, batchingData, id) {
	try {
		const response = await fetch(`${ API_URL }/batchings/${ id }`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
			body: JSON.stringify({
				registration_id: registrationId,
				batching_data: batchingData
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}