import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Header, DefaultSideBar, Text, Button, Heading } from "components";
import { detailRedeem } from "services/redeem";
import { detailBatch } from "services/batch";
import { formatAmount } from "helper/utils";

export default function ResumeRedeemPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('id');
	const [data, setData] = useState(null);
	const [batchData, setBatchData] = useState([]);

	const fetchData = async () => {
		try {
			const redeemData = await detailRedeem(id);
			const batchIds = redeemData.data.group_batch.map(item => item.batch_id);
			setData(batchIds);
			await fetchDataBatch(batchIds);
		} catch (error) {
			console.log("Error fetching redeem data:", error);
		}
	};

	const fetchDataBatch = async (batchIds) => {
		try {
			const batchDataPromises = batchIds.map(batchId => detailBatch(batchId));
			const batchDataResponses = await Promise.all(batchDataPromises);
			const allBatchData = batchDataResponses.map(response => {
				const batch = response.data.batching_data.batching_data;
				const totals = batch.reduce((acc, item) => {
					if (item.reporting_data.reporting_status !== 'expired') {
						acc.totalLoan++;
						acc.marhunValue += item.reporting_data.marhun_amount;
						acc.financingAmt += item.reporting_data.financing_amount;
						acc.outstandingBalance += item.reporting_data.outstanding_balance;
						acc.profitAmt += item.reporting_data.profit_amount;
					}
					return acc;
				}, { totalLoan: 0, marhunValue: 0, financingAmt: 0, outstandingBalance: 0, profitAmt: 0 });
				return {
					id: response.data.batching_data.id,
					totals
				};
			});
			setBatchData(allBatchData);
		} catch (error) {
			console.log("Error fetching batch data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
			<Header />
			<DefaultSideBar />
			{
				batchData.length > 0 &&
				<div className="flex flex-col px-10 min-h-[90vh] gap-10">
					<Text size="2xl" as="p" className="text-center !text-black-900 !font-bold">
						This is the summary of the batch that already change.
					</Text>
					<div className="grid grid-cols-2  gap-10">
						{ batchData.map((batch, index) => (
							<div className=" bg-white-A700 rounded-sm px-20 py-10 flex flex-col gap-5">
								<div className="flex flex-col items-start justify-start gap-[9px]">
									<Text size="xl" as="p" className="!text-blue_gray-700 !font-semibold">
										Batch ID: { batch.id }
									</Text>
								</div>
								<div key={ index } className="grid grid-cols-2 gap-y-5 gap-x-10 mb-10">
									<div className="flex flex-col items-start justify-start gap-[9px]">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Total Loan
										</Text>
										<Heading as="h2" className="!text-base">{ batch.totals.totalLoan }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-[9px]">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Total Marhun Value
										</Text>
										<Heading as="h2" className="!text-base">{ formatAmount(batch.totals.marhunValue) }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-[9px]">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Total Outstanding Balance
										</Text>
										<Heading as="h2" className="!text-base">{ formatAmount(batch.totals.outstandingBalance) }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-[9px]">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Total Financing Amount
										</Text>
										<Heading as="h2" className="!text-base">{ formatAmount(batch.totals.financingAmt) }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-[9px]">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Total Profit Amount
										</Text>
										<Heading as="h2" className="!text-base">{ formatAmount(batch.totals.profitAmt) }</Heading>
									</div>
								</div>
							</div>
						)) }
					</div>
					<Button onClick={ () => navigate('/redeem') } color="blue_900" size="xl" className="!text-xl font-bold my-10 mx-80">
						Back to Dashboard
					</Button>
				</div>
			}
		</div>
	);
}
