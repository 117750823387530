import React from 'react';
import { Text, Heading, Button } from "..";
import * as XLSX from 'xlsx';

const truncateData = (data, limit) => {
	if (data.length > limit) {
		return [...data.slice(0, limit), { financing_no: "..." }];
	}
	return data;
};

const TableRow = ({ batchId, data, prevData, prevFinancingAmount, financingAmount }) => {
	const displayPrevData = truncateData(prevData, 5);
	const displayData = truncateData(data, 5);
	return (
		<div className="flex items-center justify-center w-full hover:bg-gray-50_02 py-5">
			<div className='w-[24%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ batchId }
				</Text>
			</div>
			<div className='w-[19%] flex flex-col items-center'>
				{ displayPrevData.map((item, index) => (
					<Text key={ index } as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
						{ item.financing_no }
					</Text>
				)) }
			</div>
			<div className='w-[19%] flex flex-col items-center'>
				{ displayData.map((item, index) => (
					<Text key={ index } as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
						{ item.financing_no }
					</Text>
				)) }
			</div>
			<div className='w-[19%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ prevFinancingAmount }
				</Text>
			</div>
			<div className='w-[19%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ financingAmount }
				</Text>
			</div>
		</div>
	);
};

const downloadData = (data, totalReplacement) => {
	const flattenedData = [];

	data.forEach(item => {
		const batchId = item.batchId;
		const prevFinancingAmount = item.prevFinancingAmount;
		const financingAmount = totalReplacement.find(replacement => replacement.batchId === item.batchId)?.totalReplacementAmount || 0;

		const maxLength = Math.max(item.prevData.length, item.data.length);
		for (let i = 0; i < maxLength; i++) {
			flattenedData.push({
				BatchId: i === 0 ? batchId : '',
				Previous_Financing_No: item.prevData[i]?.financing_no || '',
				Updated_Financing_No: item.data[i]?.financing_no || '',
				Previous_Financing_Amount: i === 0 ? prevFinancingAmount : '',
				Updated_Financing_Amount: i === 0 ? financingAmount : '',
			});
		}
	});

	const worksheet = XLSX.utils.json_to_sheet(flattenedData);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
	XLSX.writeFile(workbook, "resume_redeem.xlsx");
};

const TableResumeRedeem = ({ data, totalReplacement }) => {
	return (
		<div className="flex flex-col items-center justify-start w-full gap-5">
			<Heading size="3xl" as="h1">Resume Replace Redeem </Heading>
			<div className='w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-10 '>
				<div className="flex flex-col  w-full ">
					<div className="flex items-center justify-center w-full pb-5">
						<div className='w-[24%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Batch ID
							</Text>
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] ">
								Previous SAG
							</Text>
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Updated SAG
							</Text>
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Total Financing Redemption
							</Text>
						</div>
						<div className='w-[19%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Total Replacement
							</Text>
						</div>
					</div>
					<div className='h-px bg-orange-100 w-full'></div>
					{
						data &&
						<div className="flex flex-col items-center justify-start w-full pb-10 h-[50vh] overflow-y-auto">
							{ data.map((item, index) => (
								<div key={ index } className='w-full'>
									<TableRow
										batchId={ item.batchId }
										prevData={ item.prevData }
										data={ item.data }
										prevFinancingAmount={ item.prevFinancingAmount }
										financingAmount={ totalReplacement.find(replacement => replacement.batchId === item.batchId)?.totalReplacementAmount || 0 }
									/>
									<div className='h-px bg-orange-100 w-full'></div>
								</div>
							)) }
						</div>
					}
					<div className='h-px bg-orange-100 w-full'></div>
					{
						data &&
						<div className='flex justify-end w-full mt-3'>
							<Button onClick={ () => downloadData(data, totalReplacement) } color="yellow_800" size="md" className="!text-sm font-bold whitespace-nowrap w-fit">
								Download
							</Button>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

export default TableResumeRedeem;