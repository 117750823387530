import React, { useState } from 'react';
import { Img, SelectBox, Input, Heading, Text, Button } from "..";
import { CloseSVG } from "../../assets/images";
import { CheckBox } from 'components/CheckBox';
import { useNavigate } from 'react-router';
import { deleteReport, exportReport, reportingsData } from "services/report";
import DefaultModal from 'components/DefaultModal';
import * as XLSX from 'xlsx';
import { monthOptions } from 'constant/options';
import * as loader from "../../components/Loading/loading";
import DetailsModal from 'components/ModalDetail';

const TableRow = ({ checkbox, company, period, id, totalLoan, marhunValue, outstandingBalance, financingAmt, profitAmt }) => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenDetail, setIsOpenDetail] = useState(false);

	const handleCreateBatch = () => {
		navigate(`/create-batch?id=${ id }`);
	};

	const handleDeleteReport = async () => {
		loader.showLoader();
		try {
			const successDelete = await deleteReport(id);
			setIsOpen(false);
			if (successDelete) {
				window.location.reload();
			}
		} catch (error) {
			console.error("Error fetching report data:", error);
		} finally {
			loader.hideLoader();
		}
	};

	const handleDownloadExcel = async () => {
		loader.showLoader();
		try {
			const reportsData = await fetchData(id);
			const excelData = reportsData;
			const ws = XLSX.utils.json_to_sheet(excelData);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
			const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
			const url = URL.createObjectURL(blob);

			const anchor = document.createElement('a');
			anchor.href = url;
			anchor.download = `report_data_${ company }.xlsx`;
			anchor.click();

			setTimeout(() => {
				URL.revokeObjectURL(url);
			}, 100);
		} catch (error) {
			console.error("Error downloading Excel:", error);
		} finally {
			loader.hideLoader();
		}
	};

	const fetchData = async (id) => {
		try {
			const reportsData = await exportReport(id);
			const reportingDataArray = [];
			for (let rowIdx = 0; rowIdx < reportsData.data.reportings.length; rowIdx++) {
				const reportingData = reportsData.data.reportings[rowIdx].reporting_data;
				if (reportingData && Object.keys(reportingData).length !== 0) {
					reportingDataArray.push(reportingData);
				}
			}
			reportingDataArray.sort((a, b) => {
				const keyToSortBy = 'serial_no';
				return a[keyToSortBy] - b[keyToSortBy];
			});
			return reportingDataArray;
		} catch (error) {
			console.error("Error fetching originators data:", error);
		}
	};

	return (
		<div className="flex items-center justify-center w-full hover:bg-gray-50_02">
			<CheckBox className="w-[5%]" value={ checkbox } />
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] w-[40%]">
				{ id }
			</Text>
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] w-[20%]">
				{ company }
			</Text>
			<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px] w-[15%] col-span-2">
				{ period.split('T')[0] }
			</Text>
			<div onClick={ handleCreateBatch } className='flex justify-center items-center w-[10%]'>
				<Button color="yellow_800" size="xs" className="font-bold w-fit">
					Create
				</Button>
			</div>
			<div className='flex justify-center items-center w-[10%]'>
				<Img
					onClick={ () => setIsOpenDetail(true) }
					src="images/img_carbon_view.svg"
					alt="vector_one"
					className="h-[24px] w-[24px] ml-2.5 my-[17px]"
				/>
				<Img
					onClick={ handleDownloadExcel }
					src="images/img_material_symbol_black_900.svg"
					alt="materialsymbol"
					className="h-[24px] w-[24px] ml-2.5 my-[17px] cursor-pointer"
				/>
				<Img
					onClick={ () => setIsOpen(true) }
					src="images/img_material_symbol_red_a700.svg"
					alt="materialsymbol"
					className="h-[24px] w-[24px] ml-2.5  cursor-pointer"
				/>
			</div>
			<DefaultModal isOpen={ isOpen } type='attention' title='Attention' desc='Are you sure you want to delete the report?' onNoButtonClick={ () => setIsOpen(false) } onYesButtonClick={ handleDeleteReport } />
			<DetailsModal isOpen={ isOpenDetail }
				title='Detail Report'
				onButtonClick={ () => setIsOpenDetail(false) }
				id={ id }
				totalLoan={ totalLoan }
				marhunValue={ marhunValue }
				outstandingBalance={ outstandingBalance }
				financingAmt={ financingAmt }
				ProfitAmt={ profitAmt } />
		</div>
	);
};

const TableReport = ({ data }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(50);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [checkedItems, setCheckedItems] = useState(Array.isArray(data) ? Array(data.length).fill(false) : []);
	const [selectAll, setSelectAll] = useState(false);

	const handleSearch = (e) => {
		setSearchTerm(e);
		setCurrentPage(1);
	};

	const handleSelectMonth = (selectedMonth) => {
		setSelectedMonth(selectedMonth);
	};

	const handleItemClick = (index) => {
		const newCheckedItems = [...checkedItems];
		newCheckedItems[index] = !newCheckedItems[index];
		setCheckedItems(newCheckedItems);
	};

	const handleSelectAll = () => {
		const newSelectAll = !selectAll;
		setSelectAll(newSelectAll);
		const newCheckedItems = newSelectAll ? Array(data.length).fill(true) : Array(data.length).fill(false);
		setCheckedItems(newCheckedItems);
	};

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const filteredData = data
		? data.filter(
			(item) =>
				item.company_name.toLowerCase().includes(searchTerm.toLowerCase()) &&
				(!selectedMonth || item.cut_off_date.slice(5, 7) === selectedMonth.value)
		)
		: [];
	const sortedData = filteredData.slice().sort((a, b) => {
		return new Date(b.created_at) - new Date(a.created_at);
	});

	const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="flex flex-col items-center justify-start w-full gap-5">
			<div className="flex flex-row justify-between items-center w-full">
				<div className="flex flex-row justify-start items-center gap-[19px]">
					<Heading size="xl" as="h1" className="!text-gray-700 text-center !font-bold">
						Apply Filters :
					</Heading>
					<SelectBox
						indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" /> }
						name="month"
						placeholder="Month"
						options={ monthOptions }
						onChange={ handleSelectMonth }
						className="w-[160px] gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid rounded-[5px]"
					/>
				</div>

				<div className="flex flex-row justify-start items-center gap-[15px]">
					<Input
						name="search"
						value={ searchTerm }
						placeholder="Search Name"
						onChange={ handleSearch }
						suffix={
							searchTerm?.length > 0 ? (
								<CloseSVG
									onClick={ () => setSearchTerm("") }
									height={ 27 }
									width={ 31 }
									fillColor="#7e7e7eff"
								/>
							) : <Img src="images/img_search.svg" alt="search" className="cursor-pointer" />
						}
						className="w-[267px] gap-[35px] text-gray-700 border-gray-300 border-2 border-dashed"
					/>
					{/* <Button color="yellow_800" size="sm" className="font-bold min-w-[126px]">
						Filter
					</Button> */}
				</div>
			</div>

			<div className='w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-10'>
				{/* Table */ }
				<div className="flex flex-col  w-full ">
					<div className="flex items-center justify-center w-full pb-5">
						<CheckBox className="w-[5%]" onChange={ handleSelectAll } value={ selectAll } />
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[40%]">
							Assets No
						</Text>
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[20%]">
							Company Name
						</Text>
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[15%] col-span-2">
							Cut Off Date
						</Text>
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] text-center w-[10%]">
							Create Batch
						</Text>
						<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] text-center w-[10%]">
							Action
						</Text>

					</div>
					<div className='h-px bg-orange-100 w-full'></div>
					<div className="flex flex-col items-center justify-start w-full">
						{ currentItems.map((item, index) => (
							<div key={ index } className='w-full' onClick={ () => handleItemClick(index) }>
								<TableRow
									id={ item.id_group }
									checkbox={ checkedItems[index] }
									company={ item.company_name }
									period={ item.cut_off_date }
									financingAmt={ item.total_financing_active }
									totalLoan={ item.total_loan }
									marhunValue={ item.total_marhun_active }
									outstandingBalance={ item.total_outstanding_active }
									profitAmt={ item.total_profit_active }
								/>
								<div className='h-px bg-orange-100 w-full'></div>
							</div>
						)) }
					</div>
				</div>

				{/* Pagination */ }
				<div className="flex flex-row justify-end items-center w-full">
					<div className="flex gap-3">
						<div
							onClick={ currentPage > 1 ? () => paginate(currentPage - 1) : undefined }
							className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
						>
							{ `<` }
						</div>
						{ Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }, (_, i) => (
							<div key={ i } onClick={ () => paginate(i + 1) } className={ `rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs ${ currentPage === i + 1 ? 'bg-yellow-800 text-white-A700' : 'bg-gray-100_01 text-black-900' } ` }>{ i + 1 }</div>
						)) }
						<div
							onClick={ currentPage < Math.ceil(filteredData.length / itemsPerPage) ? () => paginate(currentPage + 1) : undefined }
							className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
						>
							{ '>' }
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableReport;