import React, { useEffect, useState } from "react";
import { Header, DefaultSideBar } from "components";
import { reportingsData } from "services/report";
import { detailBatch } from 'services/batch';
import { useLocation } from 'react-router-dom';
import TableUpdateBatch from "components/TableUpdateBatch";

export default function UpdateBatch() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('id');
	const regId = queryParams.get('regId');

	const [data, setData] = useState(null);
	const [selectedMaturity, setSelectedMaturity] = useState('');
	const [selectedFinancing, setSelectedFinancing] = useState('');
	const [prevBatchData, setPrevBatchData] = useState(null);

	const fetchData = async () => {
		try {
			const reportsData = await reportingsData("", "", "", "", selectedMaturity, "", selectedFinancing, regId);
			setData(reportsData.data);
		} catch (error) {
			console.error("Error fetching reporting data:", error);
		}
	};

	const fetchPrevData = async () => {
		try {
			const batchData = await detailBatch(id);
			setPrevBatchData(batchData.data.batching_data);
		} catch (error) {
			console.log("Error fetching batch data:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, [selectedMaturity, selectedFinancing]);

	useEffect(() => {
		fetchPrevData();
	}, []);

	const handleMaturityChange = (criteria) => {
		setSelectedMaturity(criteria.value);
	};

	const handleFinancingChange = (criteria) => {
		setSelectedFinancing(criteria.value);
	};

	return (
		<div className=" w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
			<Header />
			<DefaultSideBar />
			{ data ? (
				<div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] px-10">
					<TableUpdateBatch data={ data.reportings } prevBatchData={ prevBatchData } noBatch={ id } onFinancingChange={ handleFinancingChange } onMaturityChange={ handleMaturityChange } />
				</div>
			) : (
				<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
			) }
		</div>
	);
}
