import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const shapes = {
	round: "rounded-[10px]",
};
const variants = {
	fill: {
		gray_50_01: "bg-gray-50_01 text-gray-600",
		white_A700: "bg-white-A700 text-blue_gray-900_01",
	},
};
const sizes = {
	sm: "h-[42px] pl-[19px] pr-[35px] text-xs",
	md: "h-[45px] px-[35px] text-base",
	xs: "h-[42px] pl-4 pr-[35px] text-[15px]",
};

const SelectBox = React.forwardRef(
	(
		{
			children,
			className = "",
			options = [],
			isSearchable = false,
			isMulti = false,
			indicator,
			onChange,
			shape = "round",
			size = "xs",
			variant = "fill",
			color = "white_A700",
			...restProps
		},
		ref,
	) => {
		return (
			<>
				<Select
					ref={ ref }
					options={ options }
					className={ `${ className } flex ${ (shape && shapes[shape]) || "" } ${ (size && sizes[size]) || "" } ${ (variant && variants[variant]?.[color]) || "" }` }
					isSearchable={ isSearchable }
					isMulti={ isMulti }
					onChange={ onChange }
					components={ {
						IndicatorSeparator: () => null,
						...(indicator && { DropdownIndicator: () => indicator }),
					} }
					styles={ {
						container: (provided) => ({
							...provided,
							zIndex: 0,
						}),
						control: (provided) => ({
							...provided,
							backgroundColor: "transparent",
							border: "0 !important",
							boxShadow: "0 !important",
							minHeight: "auto",
							width: "100%",
							"&:hover": {
								border: "0 !important",
							},
						}),
						input: (provided) => ({
							...provided,
							color: "inherit",
						}),
						option: (provided, state) => ({
							...provided,
							backgroundColor: state.isSelected && "#f5f5f5",
							color: state.isSelected && "#404b52",
							"&:hover": {
								backgroundColor: "#f5f5f5",
								color: "#404b52",
							},
						}),
						valueContainer: (provided) => ({
							...provided,
							padding: 0,
						}),
						placeholder: (provided) => ({
							...provided,
							margin: 0,
						}),
						menuPortal: (base) => ({ ...base, zIndex: 999999 }),
					} }
					menuPortalTarget={ document.body }
					closeMenuOnScroll={ (event) => {
						return event.target.id === "scrollContainer";
					} }
					{ ...restProps }
				/>
				{ children }
			</>
		);
	},
);

SelectBox.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array,
	isSearchable: PropTypes.bool,
	isMulti: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.string,
	indicator: PropTypes.node,
	shape: PropTypes.oneOf(["round"]),
	size: PropTypes.oneOf(["sm", "md", "xs"]),
	variant: PropTypes.oneOf(["fill"]),
	color: PropTypes.oneOf(["gray_50_01", "white_A700"]),
};

export { SelectBox };
