import React from "react";
import PropTypes from "prop-types";

const shapes = {
	round: "rounded",
};
const variants = {
	fill: {
		white_A700: "border-blue_gray-100_02 border border-solid bg-white-A700 text-yellow-800",
	},
};
const sizes = {
	xs: "h-[17px] w-[17px] pl-2 text-sm",
};

const CheckBox = React.forwardRef(
	(
		{
			className = "",
			name = "",
			children,
			label = "",
			id = "checkbox_id",
			onChange,
			value = false,
			shape = "round",
			variant = "fill",
			size = "xs",
			color = "white_A700",
			...restProps
		},
		ref,
	) => {
		const handleChange = (e) => {
			if (onChange) onChange(e?.target?.checked);
		};

		return (
			<>
				<div className={ className + " flex items-center justify-center gap-[5px] cursor-pointer" }>
					<input
						className={ ` ${ (shape && shapes[shape]) || "" } ${ (size && sizes[size]) || "" } ${ (variant && variants[variant]?.[color]) || "" }` }
						ref={ ref }
						type="checkbox"
						name={ name }
						checked={ value }
						onChange={ handleChange }
						id={ id }
						{ ...restProps }
					/>
					<label htmlFor={ id }>{ label }</label>
				</div>
				{ children }
			</>
		);
	},
);

CheckBox.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	shape: PropTypes.oneOf(["round"]),
	size: PropTypes.oneOf(["xs"]),
	variant: PropTypes.oneOf(["fill"]),
	color: PropTypes.oneOf(["white_A700"]),
	value: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
};

export { CheckBox };
