export function logout() {
	localStorage.removeItem('accessToken');
	localStorage.removeItem('userID');
	localStorage.removeItem('username');
}

export function isLoggedIn() {
	return !!localStorage.getItem('accessToken');
}

export function token() {
	return localStorage.getItem('accessToken');
}

