import { token } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function report() {
	try {
		const response = await fetch(`${ API_URL }/reportings_group?limit=100000&page=1&filter=`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function detailReport(id) {
	try {
		const response = await fetch(`${ API_URL }/reportings_group/${ id }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function reportingsData(id, minValue = "", maxValue = "", minMaturity = "", maxMaturity = "", minFinancing = "", maxFinancing = "", regId = "") {
	try {
		const response = await fetch(`${ API_URL }/reportings?exclude_expired=true&min_value=${ minValue }&max_value=${ maxValue }&limit=0&page=0&filter=&min_remaining_maturity=${ minMaturity }&max_remaining_maturity=${ maxMaturity }&min_financing_limit=${ minFinancing }&max_financing_limit=${ maxFinancing }&group_id=${ id }&registration_id=${ regId }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function actionReport(status, reason, id) {
	try {
		const response = await fetch(`${ API_URL }/reportings_group/${ id }`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
			body: JSON.stringify({
				reporting_status: status,
				reporting_rejection_reason: status === 'rejected' ? reason : '',
			}),
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function deleteReport(id) {
	try {
		const response = await fetch(`${ API_URL }/reportings_group/${ id }`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}

export async function exportReport(id) {
	try {
		const response = await fetch(`${ API_URL }/reportings-export?limit=0&page=0&filter=&group_id=${ id }`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});

		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}
