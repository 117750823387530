import React, { useEffect, useState } from "react";
import { Button, TextArea, Text, SelectBox, Img, Heading, Header, DefaultSideBar } from "components";
import { useLocation } from 'react-router-dom';
import { detailReport, actionReport } from 'services/report';
import { useNavigate } from 'react-router';
import { dropDownOptions } from 'constant/options';
import * as loader from "../../components/Loading/loading";

export default function DetailReport() {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [data, setData] = useState(null);
	const [status, setStatus] = useState('');
	const [reason, setReason] = useState('');
	const id = queryParams.get('id');

	const fetchData = async () => {
		try {
			const response = await detailReport(id);
			setData(response.data.reporting_data);
		} catch (error) {
			console.log('Error fetching data:', error);
		}
	};

	const handleActionKyc = async () => {
		loader.showLoader();
		try {
			const response = await actionReport(status, reason, data.id_group);
			if (response) {
				navigate('/report');
			}
		} catch (error) {
			console.log('Error fetching data:', error);
		} finally {
			loader.hideLoader();
		}
	};

	useEffect(() => {
		fetchData();
	}, [id]);

	return (
		<div className=" w-full relative bg-white-A700 pl-[81px] pt-[10vh]">
			<Header />
			<DefaultSideBar />
			<div className="flex flex-row justify-start p-[27px]">
				{ data ? (
					<div className="flex flex-col justify-center w-full ml-2 p-[43px] border-blue_gray-100_01 border border-solid bg-white-A700 rounded-[10px]">
						<Heading size="2xl" as="h1">
							Report Detail
						</Heading>
						<div className="flex flex-col items-center">
							<div className="flex flex-row justify-between p-5 w-[50%]">
								<div className="flex flex-col gap-5 w-full">
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Company Name:
										</Text>
										<Heading as="h5">{ data.company_name }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2 mt-5">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Total data:
										</Text>
										<Heading as="h6">{ data.total_loan_tagged }</Heading>
									</div>
								</div>
								<div className="flex flex-col gap-5 w-full">
									<div className="flex flex-col items-start justify-start gap-2">
										<div className="flex flex-col items-start justify-start gap-2">
											<Text size="xl" as="p" className="!text-blue_gray-700">
												Period:
											</Text>
											<Heading as="h5">{ data.month + " " + data.year }</Heading>
										</div>
									</div>
									<div className="flex flex-col items-start justify-start gap-2 w-full max-w-[200px] mt-5">
										<div className="flex flex-row justify-start w-full">
											<Heading size="lg" as="p" className="!text-blue_gray-900_01 !font-opensans">
												Action
											</Heading>
										</div>
										<SelectBox
											indicator={ <Img src="images/img_icon_field_clear.svg" alt="Icon / Field / Clear" /> }
											name="rejected"
											placeholder="Select Status"
											options={ dropDownOptions }
											onChange={ (value) => { setStatus(value.value); } }
											className="w-full gap-px font-opensans border-gray-300_01 border border-solid !rounded-lg"
										/>
									</div>
								</div>
							</div>
							{
								status === 'rejected' &&
								<div className="flex flex-row justify-start p-5 w-[50%]">
									<div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
										<Text as="p" className="!text-blue_gray-700 !font-medium">
											Rejected Reason
										</Text>
										<TextArea
											name="input_one"
											onChange={ (e) => { setReason(e); } }
											placeholder="Enter your rejected reason"
											className="w-full text-blue_gray-500"
										/>
									</div>
								</div>
							}
							<div className="flex justify-between w-[50%] p-5">
								<Button
									onClick={ handleActionKyc }
									color="indigo_900"
									size="md"
									disable={ status === '' }
									className="tracking-[0.40px] font-poppins uppercase font-medium w-[183px]"
								>
									Update
								</Button>
								<div></div>
							</div>
						</div>
					</div>
				) : (
					<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
				) }
			</div>
		</div >
	);
}