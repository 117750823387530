import React from "react";
import PropTypes from "prop-types";

const shapes = {
	round: "rounded",
};
const variants = {
	fill: {
		indigo_900: "bg-indigo-900 shadow-lg text-white-A700",
		indigo_900_7f: "bg-indigo-900_7f shadow-xl text-white-A700",
		blue_900: "bg-blue-800 text-white-A700 shadow-lg ",
		white_A700: "bg-white-A700 text-indigo-900",
		yellow_800: "bg-yellow-800 text-white-A700 shadow-lg ",
		gray_100_01: "bg-blue_gray-700 text-white-A700",

	},
};
const sizes = {
	xl: "h-[63px] px-[35px] text-3xl",
	sm: "h-[40px] px-[35px] text-base",
	md: "h-[45px] px-[35px] text-sm",
	lg: "h-[57px] px-[35px] text-sm",
	xs: "h-[24px] px-[9px] text-xs",
};

const Button = ({
	children,
	className = "",
	leftIcon,
	rightIcon,
	shape = "round",
	variant = "fill",
	size = "xs",
	color = "gray_100_01",
	disable = false,
	...restProps
}) => {
	let dynamicColor = disable ? "indigo_900_7f" : color;
	return (
		<button
			disabled={ disable }
			className={ `${ className } flex items-center justify-center text-center cursor-pointer
			rounded ${ (shape && shapes[shape]) || "" } ${ (size && sizes[size]) || "" } ${ (variant && variants[variant]?.[dynamicColor]) || "" }` }
			{ ...restProps }
		>
			{ !!leftIcon && leftIcon }
			{ children }
			{ !!rightIcon && rightIcon }
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	leftIcon: PropTypes.node,
	rightIcon: PropTypes.node,
	shape: PropTypes.oneOf(["round"]),
	size: PropTypes.oneOf(["xl", "sm", "md", "lg", "xs"]),
	variant: PropTypes.oneOf(["fill"]),
	color: PropTypes.oneOf(["indigo_900", "indigo_900_7f", "blue_900", "white_A700", "yellow_800", "gray_100_01"]),
};

export { Button };
