import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Originator from "pages/Originator";
import DetailOriginator from "pages/DetailOriginator";
import Dashboard from "pages/Dashboard";
import Report from "pages/Report";
import DetailReport from "pages/DetailReport";
import BatchPage from "pages/Batch";
import LoginPage from "pages/Login";
import CreateBatch from "pages/CreateBatch";
import EditBatch from "pages/EditBatch";
import UpdateBatch from "pages/UpdateBatch";
import RedeemPage from "pages/Redeem";
import DetailRedeem from "pages/DetailRedeem";
import ResumeRedeemPage from "pages/ResumeRedeem";

const ProjectRoutes = () => {
	let element = useRoutes([
		{ path: "/", element: <Dashboard /> },

		{ path: "*", element: <NotFound /> },
		{
			path: "login",
			element: <LoginPage />,
		},
		{
			path: "originator",
			element: <Originator />,
		},
		{
			path: "originator-detail",
			element: <DetailOriginator />,
		},
		{
			path: "report",
			element: <Report />,
		},
		{
			path: "report-detail",
			element: <DetailReport />,
		},
		{
			path: "batch",
			element: <BatchPage />,
		},
		{
			path: "create-batch",
			element: <CreateBatch />,
		},
		{
			path: "edit-batch",
			element: <EditBatch />,
		},
		{
			path: "update-batch",
			element: <UpdateBatch />,
		},
		{
			path: "redeem",
			element: <RedeemPage />,
		},
		{
			path: "detail-redeem",
			element: <DetailRedeem />,
		},
		{
			path: "resume-redeem",
			element: <ResumeRedeemPage />
		}
	]);

	return element;
};

export default ProjectRoutes;
