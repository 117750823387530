import React, { useState } from "react";
import { Button, Heading, TextArea } from "..";
import { default as ModalProvider } from "react-modal";

export default function ModalRejected({ isOpen, onYesButtonClick, onNoButtonClick, ...props }) {
	const [reason, setReason] = useState('');
	const handleYesButton = () => {
		if (onYesButtonClick) {
			onYesButtonClick(reason);
		}
	};
	const handleNoButton = () => {
		if (onNoButtonClick) {
			onNoButtonClick();
		}
	};

	return (
		<ModalProvider { ...props } style={ {
			overlay: {
				backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
			},
			content: {
				outline: "none",
			},
		} }
			appElement={ document.getElementById("root") } isOpen={ isOpen } className="w-[30%]">
			<div className="flex flex-col items-start justify-start w-full p-10 bg-white-A700 rounded-[10px]">
				<Heading size="lg" as="h1" className='!font-poppins !font-semibold text-black-900'>
					Rejected Reason
				</Heading>
				<TextArea
					onChange={ (e) => { setReason(e); } }
					name="input_one"
					placeholder="Enter your rejected reason"
					className="w-full text-blue_gray-500 mt-5"
				/>
				<div className="flex justify-between w-full mt-10">
					<Button
						onClick={ handleYesButton }
						size="sm"
						color="white_A700"
						className="tracking-[0.40px] font-poppins uppercase font-semibold w-fit rounded-[10px] !px-10 !py-6 !shadow-[0_10px_15px_0px_rgba(0,0,0,0.2)] "
					>
						Reject
					</Button>
					<Button
						onClick={ handleNoButton }
						size="sm"
						color="blue_900"
						className="tracking-[0.40px] font-poppins uppercase font-semibold w-fit rounded-[10px] !px-10 !py-6 !shadow-[0_10px_15px_0px_rgba(0,0,0,0.2)] "
					>
						Cancel
					</Button>
				</div>
			</div>
		</ModalProvider>
	);
}
