import { token } from 'constant/localStorage';

const API_URL = process.env.REACT_APP_API_URL;

export async function login(username, password) {
	try {
		const response = await fetch(`${ API_URL }/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username, password }),
		});

		if (!response.ok) {
			const errorData = await response.json();
			throw errorData.code_message;
		}
		const data = await response.json();

		localStorage.setItem('accessToken', data.data.token);
		localStorage.setItem('userID', data.data.id);
		localStorage.setItem('username', data.data.username);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function dashboard() {
	try {
		const response = await fetch(`${ API_URL }/dashboard?date_start=&date_end=`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${ token() }`
			},
		});
		if (!response.ok) {
			const errorData = await response.json();
			if (errorData.code === 401 && errorData.code_type === "unAuthorized") {
				window.location.href = "/login";
			} else {
				throw errorData.code_message;
			}
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw error;
	}
}