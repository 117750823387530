import React, { useEffect, useState } from "react";
import { Button, TextArea, Text, SelectBox, Img, Heading, Header, DefaultSideBar } from "../../components";
import { useLocation } from 'react-router-dom';
import { detailOriginator, actionKyc } from 'services/originator';
import { useNavigate } from 'react-router';
import { dropDownOptions } from 'constant/options';
import * as loader from "../../components/Loading/loading";

export default function DetailOriginator() {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [data, setData] = useState(null);
	const [status, setStatus] = useState('');
	const [reason, setReason] = useState('');
	const [submitError, setSubmitError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const id = queryParams.get('id');

	const fetchData = async () => {
		try {
			const response = await detailOriginator(id);
			setData(response.data.registration_data);
		} catch (error) {
			console.log('Error fetching data:', error);
		}
	};

	const handleActionKyc = async () => {
		loader.showLoader();
		setIsLoading(true);
		try {
			const response = await actionKyc(status, reason, id);
			setIsLoading(false);
			if (response) {
				navigate('/originator');
			}
		} catch (error) {
			setSubmitError(error);
		} finally {
			loader.hideLoader();
		}
	};

	function openPDFInNewTab(url) {
		const fileId = url.split('id=')[1];
		const newUrl = `https://drive.google.com/file/d/${ fileId }/view`;
		window.open(newUrl, "_blank");
	}

	useEffect(() => {
		fetchData();
	}, [id]);

	return (
		<div className=" w-full relative bg-white-A700 pl-[81px] pt-[10vh]">
			<Header />
			<DefaultSideBar />
			<div className="flex flex-row justify-start p-[27px]">
				{ data && !isLoading ? (
					<div className="flex flex-col justify-center w-full ml-2 p-[43px] border-blue_gray-100_01 border border-solid bg-white-A700 rounded-[10px]">
						<div className="grid grid-cols-3 gap-x-8 justify-between p-5 w-full">
							<div className="flex flex-col items-start justify-start w-full">
								<Heading size="2xl" as="h1">
									Organization Detail
								</Heading>
								<div className="flex flex-col gap-5 mt-8">
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Company Name:
										</Text>
										<Heading as="h5">{ data.company }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Address:
										</Text>
										<Heading as="h6">{ data.address }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Registration SKM:
										</Text>
										<Heading as="h6">{ data.registrasi_skm }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Phone Number:
										</Text>
										<Heading as="h6">{ data.company_phone }</Heading>
									</div>
									<div className="flex justify-start items-center gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											License:
										</Text>
										<Img
											onClick={ () => openPDFInNewTab(data.file_license) }
											src="images/img_lets_icons_view_light.svg"
											alt="letsiconsview"
											className="h-[24px] w-[24px]"
										/>
									</div>
								</div>
							</div>
							<div className="flex flex-col items-start justify-start w-full">
								<Heading size="2xl" as="h1">
									Bank Account
								</Heading>
								<div className="flex flex-col gap-5 mt-8">
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Bank Name:
										</Text>
										<Heading as="h5">{ data.bank_name }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Account Name
										</Text>
										<Heading as="h6">{ data.bank_account_name }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Account Number
										</Text>
										<Heading as="h6">{ data.bank_account_number }</Heading>
									</div>
								</div>
								<div className="flex flex-col items-center justify-start w-full max-w-[200px] gap-[7px] mt-8">
									<div className="flex flex-row justify-start w-full">
										<Heading size="lg" as="p" className="!text-blue_gray-900_01 !font-opensans">
											Action
										</Heading>
									</div>
									<SelectBox
										indicator={ <Img src="images/img_icon_field_clear.svg" alt="Icon / Field / Clear" /> }
										name="rejected"
										placeholder="Select Status"
										options={ dropDownOptions }
										onChange={ (value) => { setStatus(value.value); } }
										className="w-full gap-px font-opensans border-gray-300_01 border border-solid !rounded-lg"
									/>
								</div>
							</div>
							<div className="flex flex-col items-start justify-start w-full">
								<Heading size="2xl" as="h1">
									Representative Account
								</Heading>
								<div className="flex flex-col gap-5 mt-8">
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Name:
										</Text>
										<Heading as="h5">{ data.fullname }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Email:
										</Text>
										<Heading as="h6">{ data.email }</Heading>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text size="xl" as="p" className="!text-blue_gray-700">
											Phone Number:
										</Text>
										<Heading as="h6">{ data.phone }</Heading>
									</div>
								</div>
							</div>
						</div>
						{
							status === 'rejected' &&
							<div className="flex flex-row justify-start w-[69%] p-5">
								<div className="flex flex-col items-start justify-start w-full pt-0.5 gap-1.5">
									<Text as="p" className="!text-blue_gray-700 !font-medium">
										Rejected Reason
									</Text>
									<TextArea
										onChange={ (e) => { setReason(e); } }
										name="input_one"
										placeholder="Enter your rejected reason"
										className="w-full text-blue_gray-500"
									/>
								</div>
							</div>
						}
						{ submitError && <Text as="p" className="text-red-500">{ submitError }</Text> }
						<div className="flex justify-center w-full">
							<Button
								onClick={ handleActionKyc }
								color="indigo_900"
								size="md"
								disable={ status === '' }
								className="mt-[50px] tracking-[0.40px] font-poppins uppercase font-medium w-[183px] "
							>
								Update
							</Button>
						</div>
					</div>
				) : (
					<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
				) }
			</div>
		</div>
	);
}