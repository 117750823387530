import React, { useState, useEffect } from 'react';
import { Img, SelectBox, Heading, Text, Button } from "..";
import { CheckBox } from 'components/CheckBox';
import { maturityOptions, financingOptions } from 'constant/options';
import { updateBatch } from 'services/batch';
import { useNavigate } from 'react-router';
import * as loader from "../../components/Loading/loading";

const TableRow = ({ checkbox, refNo, disbursement, maturity, marhun, financing, principal, status }) => {
	return (
		<div className={ `flex items-center justify-center w-full py-5 ${ status === "expired" ? 'bg-gray-200' : 'hover:bg-gray-50_02' }` }>
			<CheckBox className="w-[3%]" value={ checkbox } />
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ refNo }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ disbursement.split('T')[0] }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ maturity.split('T')[0] }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ marhun }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ financing }
				</Text>
			</div>
			<div className='w-[15%] flex justify-center'>
				<Text as="p" className="!text-blue_gray-900_06 tracking-[-0.14px]">
					{ principal }
				</Text>
			</div>
			<div className='w-[7%] flex justify-center'>
				<Text as="p" className={ `${ status === 'batched' ? '!text-green' : '!text-red-A700' } !font-semibold tracking-[-0.14px]` }>
					{ status.charAt(0).toUpperCase() + status.slice(1) }
				</Text>
			</div>
		</div>
	);
};

const TableEditBatch = ({ data, unfilteredData, regId, noBatch, onFinancingChange, onMaturityChange }) => {
	const navigate = useNavigate();
	const [selectedMaturity, setSelectedMaturity] = useState('');
	const [selectedFinancing, setSelectedFinancing] = useState('');
	const [checkedItems, setCheckedItems] = useState(Array.isArray(data) ? Array(data.length).fill(false) : []);
	const [selectAll, setSelectAll] = useState(false);
	const [sortedData, setSortedData] = useState([]);
	const [sortBy, setSortBy] = useState('financing_no');
	const [sortDirection, setSortDirection] = useState('asc');

	const handleExpiredBatch = async () => {
		loader.showLoader();
		try {
			const checkedItemsFromSorted = sortedData.filter((item, index) => checkedItems[index]);
			const financingNumbers = unfilteredData.map((item) => {
				const checkedItem = checkedItemsFromSorted.find((sortedItem) => sortedItem.reporting_data.financing_no === item.reporting_data.financing_no);
				return {
					financing_no: item.reporting_data.financing_no,
					status: checkedItem ? "expired" : item.status
				};
			});
			const successExpired = await updateBatch(regId, financingNumbers, noBatch);
			if (successExpired) {
				window.location.reload();
			}
		} catch (error) {
			console.log("error");
		} finally {
			loader.hideLoader();
		}
	};

	const handleItemClick = (index, status) => {
		if (status !== "expired") {
			const newCheckedItems = [...checkedItems];
			newCheckedItems[index] = !newCheckedItems[index];
			setCheckedItems(newCheckedItems);
		}
	};

	const handleSelectAll = () => {
		const newSelectAll = !selectAll;

		const newCheckedItems = sortedData.map(item => {
			return newSelectAll && item.status !== "expired";
		});
		setSelectAll(newSelectAll);
		setCheckedItems(newCheckedItems);
	};

	const handleSort = (column) => {
		if (sortBy === column) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortBy(column);
			setSortDirection('desc');
		}
	};

	useEffect(() => {
		const sorted = [...data].sort((a, b) => {
			const aValue = a.reporting_data[sortBy];
			const bValue = b.reporting_data[sortBy];
			if (aValue < bValue) {
				return sortDirection === 'asc' ? -1 : 1;
			}
			if (aValue > bValue) {
				return sortDirection === 'asc' ? 1 : -1;
			}
			return 0;
		});
		setSortedData(sorted);
	}, [data, sortBy, sortDirection]);

	const countTotalMahunValue = () => {
		if (!data || !checkedItems) return 0;
		const totalMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (checkedItems[index]) {
				return accumulator + currentItem.reporting_data.marhun_amount;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalMahunValue.toFixed(2));
	};
	const countTotalFinancingAmount = () => {
		if (!sortedData || !checkedItems) return 0;
		const totalCheckedMahunValue = sortedData.reduce((accumulator, currentItem, index) => {
			if (checkedItems[index]) {
				return accumulator + currentItem.reporting_data.financing_amount;
			} else {
				return accumulator;
			}
		}, 0);
		return parseFloat(totalCheckedMahunValue.toFixed(2));
	};

	const isAnyChecked = checkedItems.some(item => item);

	const handleFilterChange = () => {
		onFinancingChange(selectedFinancing);
		onMaturityChange(selectedMaturity);
	};

	return (
		<div className="flex flex-col items-center justify-start w-full gap-5">
			<div className="flex flex-col items-center w-full">
				<div className="flex flex-row justify-between w-full items-center">
					<Heading size="xl" as="h1" className="!text-gray-700 text-center !font-bold">
						Apply Filters :
					</Heading>
					<div className='flex justify-center gap-10'>
						<Button onClick={ () => navigate(`/update-batch?id=${ noBatch }&regId=${ regId }`) } color="yellow_800" size="md" className={ `!text-sm font-bold min-w-[126px] ${ isAnyChecked ? 'cursor-not-allowed opacity-50' : '' }` } disabled={ isAnyChecked }>
							Add New Loan
						</Button>
						<Button onClick={ handleExpiredBatch } color="gray_100_01" size="md" className={ `!text-sm font-bold min-w-[126px] ${ !isAnyChecked ? 'cursor-not-allowed opacity-50' : '' }` } disabled={ !isAnyChecked }>
							Mark as Expired
						</Button>
					</div>
				</div>
				<div className="flex flex-row justify-start items-end gap-10 w-full">
					<div className='flex flex-col gap-2'>
						<Text className='!text-blue_gray-100_02 !font-bold'>Remaining Maturity (Max)</Text>
						<SelectBox
							indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" className="absolute" /> }
							name="month"
							placeholder=""
							options={ maturityOptions }
							onChange={ (value) => setSelectedMaturity(value) }
							className="w-full gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
						/>
					</div>
					<div className='flex flex-col gap-2'>
						<Text className='!text-blue_gray-100_02 !font-bold'>Financing Limit ( % )</Text>
						<SelectBox
							indicator={ <Img src="images/img_arrowdown_blue_gray_900_02.svg" alt="arrow_down" className="absolute" /> }
							name="status"
							placeholder=""
							options={ financingOptions }
							onChange={ (value) => setSelectedFinancing(value) }
							className="w-full gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
						/>
					</div>
					<Button onClick={ handleFilterChange } color="yellow_800" size="sm" className="font-bold min-w-[126px]">
						Filter
					</Button>
					<div className='flex flex-col gap-3'>
						<Text className='!text-blue-800 !font-bold'>Total Selected Marhun Value</Text>
						<Text className='!text-yellow-100 !font-bold'>{ countTotalMahunValue() }</Text>
					</div>
					<div className='flex flex-col gap-3'>
						<Text className='!text-blue-800 !font-bold'>Total Selected Financing Amount</Text>
						<Text className='!text-yellow-100 !font-bold'>{ countTotalFinancingAmount() }</Text>
					</div>
				</div>
			</div>
			<div className='w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-10'>
				{/* Table */ }
				<div className="flex flex-col  w-full ">
					<div className="flex items-center justify-center w-full pb-5">
						<CheckBox className="w-[3%]" onChange={ handleSelectAll } value={ selectAll } />
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px] ">
								SAG No / Financing Ref No
							</Text>
							<Img onClick={ () => handleSort('financing_no') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'financing_no' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Disbursement Date
							</Text>
							<Img onClick={ () => handleSort('disbursement_date') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'disbursement_date' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Maturity Date
							</Text>
							<Img onClick={ () => handleSort('maturity_date') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'maturity_date' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Marhun Value (RM)
							</Text>
							<Img onClick={ () => handleSort('marhun_amount') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'marhun_amount' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Financing Amount (RM)
							</Text>
							<Img onClick={ () => handleSort('financing_amount') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'financing_amount' && '!rotate-180' }` } />
						</div>
						<div className='w-[15%] flex justify-center items-center gap-1'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Outstanding Balance (RM)
							</Text>
							<Img onClick={ () => handleSort('outstanding_balance') } src='images/img_arrow_down.svg' className={ `${ sortDirection === 'desc' && sortBy === 'outstanding_balance' && '!rotate-180' }` } />
						</div>
						<div className='w-[7%] flex justify-center items-center'>
							<Text as="p" className="!font-bold !text-blue_gray-200 tracking-[-0.14px]">
								Status
							</Text>
						</div>
					</div>
					<div className='h-px bg-orange-100 w-full'></div>
					<div className="flex flex-col items-center justify-start w-full pb-10">
						{ sortedData.map((item, index) => (
							<div key={ index } className='w-full' onClick={ () => handleItemClick(index, item.status) }>
								<TableRow
									refNo={ item.reporting_data.financing_no }
									disbursement={ item.reporting_data.disbursement_date }
									maturity={ item.reporting_data.maturity_date }
									marhun={ item.reporting_data.marhun_amount }
									financing={ item.reporting_data.financing_amount }
									principal={ item.reporting_data.outstanding_balance }
									checkbox={ checkedItems[index] }
									status={ item.status }
								/>
								<div className='h-px bg-orange-100 w-full'></div>
							</div>
						)) }
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableEditBatch;