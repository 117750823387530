import React, { useEffect, useState } from "react";
import { Header, DefaultSideBar } from "components";
import TableRedeem from "components/TableRedeem";
import { redeem } from "services/redeem";

export default function RedeemPage() {
	const [data, setData] = useState(null);

	const fetchData = async () => {
		try {
			const redeemData = await redeem();
			setData(redeemData.data);
		} catch (error) {
			console.error("Error fetching redeem data:", error);
		}
	};


	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className=" w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
			<Header />
			<DefaultSideBar />
			{ data ? (
				<div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] px-10">
					<TableRedeem data={ data.redeems } />
				</div>
			) : (
				<div className="flex w-full h-[90vh] justify-center items-center">Loading...</div>
			) }
		</div>
	);
}
