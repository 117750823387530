import React, { useState, useEffect } from "react";
import { Img, Heading, Text, Header, DefaultSideBar } from "components";
import { dashboard } from "services/auth";

export default function DashboardPage() {
	const [data, setData] = useState(null);

	const fetchData = async () => {
		try {
			const dashboardData = await dashboard();
			const koperasiData = dashboardData.data.dashboard_data.dashboard_arr.find(item => item.title === "Koperasi");
			setData(koperasiData ? koperasiData.data : null);
		} catch (error) {
			console.error("Error fetching Dashboard data:", error);
		}
	};


	useEffect(() => {
		fetchData();
	}, []);

	const username = localStorage.getItem('username');

	return (
		<div className=" w-full relative bg-gray-100 pl-[81px] pt-[10vh] h-screen">
			<Header />
			<DefaultSideBar />
			{
				data &&
				<div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] px-10">
					<Heading size="4xl" as="h1" className="ml-3.5 !text-blue_gray-600 !font-leaguespartan">
						Hi, { username }
					</Heading>
					<div className="grid grid-cols-2 w-full gap-x-20 gap-y-10 mt-10">
						{ data.map((data) => (
							<div key={ data.title } className="flex flex-row justify-start p-10 bg-white-A700 shadow-2xl rounded-[30px] w-full">
								<div className="flex flex-row justify-start items-center w-full gap-[22px] px-10">
									<div className="h-[98px] w-[98px] relative">
										<div className="justify-center h-[98px] w-full left-0 bottom-0 right-0 top-0 m-auto bg-gradient absolute rounded-[50%]" />
										<Img
											src="images/img_profile_2user.svg"
											alt="profile2user"
											className="justify-center h-[49px] left-0 bottom-0 right-0 top-0 m-auto absolute"
										/>
									</div>
									<div className="flex flex-col items-start justify-start gap-2">
										<Text as="p" className="!text-gray-500_02 tracking-[-0.14px] !font-poppins">
											{ data.title }
										</Text>
										<Heading size="3xl" as="h2" className="!text-blue_gray-900_01 tracking-[-0.32px]">
											{ data.value }
										</Heading>
									</div>
								</div>
							</div>
						)) }
					</div>
					{/* <div className="grid grid-cols-2 gap-10 w-full">
					<div className="flex flex-col items-start justify-start w-full gap-[21px] p-5 bg-white-A700 rounded-[10px]">
						<Text size="2xl" as="p" className="ml-[19px] !text-blue_gray-600 !font-dmsans">
							Status
						</Text>
						<div className="flex flex-row justify-center w-[89%] mb-4 ml-[19px]">
							<div className="flex flex-col items-start justify-start w-full gap-[27px]">
								<div className="flex flex-row justify-start w-[45%] gap-5">
									<div className="flex flex-row justify-start items-center w-[45%] gap-1.5">
										<Img src="images/img_color.svg" alt="color_one" className="h-[12px]" />
										<Text as="p" className="!text-black-900 !font-dmsans">
											Active{ " " }
										</Text>
									</div>
									<div className="flex flex-row justify-start items-center w-[45%] gap-1.5">
										<Img src="images/img_color_blue_gray_50.svg" alt="color_three" className="h-[12px]" />
										<Text as="p" className="!text-black-900 !font-dmsans">
											Closed
										</Text>
									</div>
								</div>
								<div className="flex flex-row justify-start items-end w-full gap-4">
									<LineChart />
								</div>
							</div>
						</div>
					</div> */}
					{/* <div className="flex flex-col justify-between w-full gap-[21px] p-5 bg-white-A700 rounded-[10px]">
						<Text size="2xl" as="p" className="ml-5 !text-blue_gray-600 !font-dmsans">
							Grafik
						</Text>
						<Grafik />
					</div> */}
					{/* </div> */ }
				</div>
			}
		</div >
	);
}

