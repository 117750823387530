import React, { useState, useEffect } from 'react';
import { Text, Img } from "..";
import { default as ModalProvider } from "react-modal";

const TableRow = ({ index, refNo, status }) => {
	return (
		<div className="flex w-full py-5 gap-5">
			<Text as="p" className="!w-[10%] !text-blue_gray-900_06 tracking-[-0.14px]">
				{ index }
			</Text>
			<Text as="p" className="!w-[70%] !text-blue_gray-900_06 tracking-[-0.14px]">
				{ refNo }
			</Text>
			<Text as="p" className="!w-[20%] !text-blue_gray-900_06 tracking-[-0.14px]">
				{ status }
			</Text>
		</div>
	);
};

const DetailsModal = ({ isOpen, onClose, data, ...props }) => {
	return (
		<ModalProvider { ...props } style={ {
			overlay: {
				backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
			},
			content: {
				outline: "none",
			},
		} }
			appElement={ document.getElementById("root") } isOpen={ isOpen } onRequestClose={ onClose } className="w-[30%] max-h-[70vh] bg-white-A700 p-10 rounded-[10px] overflow-y-auto">
			<div className='flex gap-5 pb-5 w-full'>
				<Text as="p" className=" !w-[10%] !font-bold !text-blue_gray-200 tracking-[-0.14px]">
					No.
				</Text>
				<Text as="p" className="!w-[70%] !font-bold !text-blue_gray-200 tracking-[-0.14px]">
					Financing Ref No
				</Text>
				<Text as="p" className="!w-[20%] !font-bold !text-blue_gray-200 tracking-[-0.14px]">
					Status
				</Text>
			</div>
			<div className='h-px bg-orange-100 w-full'></div>
			<div className="flex flex-col items-center justify-start w-full pb-5">
				{ data.reportings.map((item, index) => (
					<div key={ index } className='w-full' >
						<TableRow
							index={ index + 1 }
							refNo={ item.financing_no }
							status={ item.reporting_status }
						/>
						<div className='h-px bg-orange-100 w-full'></div>
					</div>
				)) }
			</div>
		</ModalProvider >
	);
};



const TableDetailRedeem = ({ data, totalReplacement }) => {
	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		const body = document.querySelector('body');
		if (isOpen) {
			body.style.overflow = 'hidden';
		} else {
			body.style.overflow = 'auto';
		}
		return () => {
			body.style.overflow = 'auto';
		};
	}, [isOpen]);

	return (
		<div className='w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-5'>
			{/* Table */ }
			<Text as="p" className="!font-bold tracking-[-0.14px]">
				{ data.batch_id }
			</Text>
			<div className="flex flex-row justify-start gap-10 items-end w-full">
				<div className='flex flex-col gap-3'>
					<Text className='!text-blue-800 !font-bold'>Total Financing Redemption</Text>
					<Text className='!text-yellow-100 !font-bold'>{ data.total_financing_amount }</Text>
				</div>
				<div className='flex flex-col gap-3'>
					<Text className='!text-blue-800 !font-bold'>Total Replacement</Text>
					<Text className='!text-yellow-100 !font-bold'>{ totalReplacement }</Text>
				</div>
			</div>
			<div className='h-px bg-yellow-800_87' />
			<div className='flex justify-end'>
				<Img onClick={ () => setIsOpen(!isOpen) } src="images/img_arrow_right.svg" alt="arrow_right" className="w-4 h-4" />
			</div>
			<DetailsModal isOpen={ isOpen } data={ data } onClose={ closeModal } />
		</div>
	);
};

export default TableDetailRedeem;