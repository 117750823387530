import React from "react";

const sizes = {
  "3xl": "text-[32px] font-semibold leading-[100%]",
  "2xl": "text-xl font-semibold leading-[19px]",
  xl: "text-base font-semibold leading-5",
  "4xl": "text-[42px] font-bold",
  s: "text-xs font-bold",
  md: "text-[13px] font-semibold leading-4",
  xs: "text-[8px] font-bold",
  lg: "text-[15px] font-semibold",
};

const Heading = ({ children, className = "", size = "md", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-black-900 font-inter ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
