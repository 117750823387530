import React from "react";
import { Button, Text, Heading } from "./..";
import { default as ModalProvider } from "react-modal";

export default function DefaultModal({ isOpen, type, title, desc, onYesButtonClick, onNoButtonClick, ...props }) {
	const handleYesButton = () => {
		if (onYesButtonClick) {
			onYesButtonClick();
		}
	};
	const handleNoButton = () => {
		if (onNoButtonClick) {
			onNoButtonClick();
		}
	};

	return (
		<ModalProvider { ...props } style={ {
			overlay: {
				backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
			},
			content: {
				outline: "none",
			},
		} }
			appElement={ document.getElementById("root") } isOpen={ isOpen } className="w-[30%]">
			<div className="flex flex-col items-start justify-start w-full p-10 bg-white-A700 rounded-[10px]">
				<Heading size="lg" as="h1" className={ `!font-poppins !font-semibold ${ type === 'success' ? 'text-green' : type === 'error' ? 'text-red-A700' : 'text-black-900' }` }>
					{ title }
				</Heading>
				<Text as="p" className="w-[90%] mt-3 text-blue_gray-700">
					{ desc }
				</Text>
				<div className="flex justify-between w-full mt-10">
					<Button
						onClick={ handleYesButton }
						size="sm"
						color="white_A700"
						className="tracking-[0.40px] font-poppins uppercase font-semibold w-fit rounded-[10px] !px-10 !py-6 !shadow-[0_10px_15px_0px_rgba(0,0,0,0.2)] "
					>
						YES
					</Button>
					<Button
						onClick={ handleNoButton }
						size="sm"
						color="blue_900"
						className="tracking-[0.40px] font-poppins uppercase font-semibold w-fit rounded-[10px] !px-10 !py-6 !shadow-[0_10px_15px_0px_rgba(0,0,0,0.2)] "
					>
						NO
					</Button>
				</div>
			</div>
		</ModalProvider>
	);
}
